import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import { AppDTO, CreateAppArgsType } from "@bucketco/shared/appAPI";

import api from "@/common/utils/api";

export function useAppCreateMutation(
  options: Omit<
    UseMutationOptions<AppDTO, AxiosError<ErrorResponse>, CreateAppArgsType>,
    "mutationKey" | "mutationFn"
  > = {},
) {
  return useMutation({
    mutationFn: (data) =>
      api.post<"/apps">("/apps", data).then((res) => res.data.app),
    ...options,
  });
}
