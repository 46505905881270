import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { RiSettingsLine, RiSlackLine } from "react-icons/ri";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  HStack,
  Tab as ChakraTab,
  TabList,
  TabPanel,
  TabPanels,
  TabProps,
  Tabs,
  Text,
} from "@chakra-ui/react";

import { FeatureSettingsSections } from "@bucketco/shared/urls";

import AutoFeedbackSurveysIcon from "@/common/assets/auto-feedback-surveys-icon.svg?react";
import StarsIcon from "@/common/assets/stars-icon.svg?react";
import ErrorBoundary from "@/common/components/ErrorBoundary";
import { useFeature } from "@/common/hooks/useFeatureFlags";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { SettingsFeedback } from "@/feature/pages/settings/SettingsFeedback";
import { SettingsGeneral } from "@/feature/pages/settings/SettingsGeneral";
import { SettingsSlack } from "@/feature/pages/settings/SettingsSlack";
import { SettingsUsage } from "@/feature/pages/settings/SettingsUsage";

function Tab({
  children,
  to,
  ...props
}: { children: React.ReactNode; to: string } & TabProps) {
  return (
    <ChakraTab as={Link} to={to} {...props}>
      {children}
    </ChakraTab>
  );
}

type Route = {
  to: FeatureSettingsSections;
  name: string;
  icon: JSX.Element;
  panel: JSX.Element;
  meta?: Record<string, any>;
};

export default function FeatureSettings() {
  useEffect(() => {
    segmentAnalytics.track("Feature Settings Opened");
  }, []);

  const { section = "general" } = useParams() as {
    section: FeatureSettingsSections;
  };

  const { isEnabled: hasReorganizedFeatureTabs } = useFeature(
    "reorganized-feature-tabs",
  );

  const routes: Route[] = [
    {
      name: "General",
      to: "general",
      icon: <RiSettingsLine size={18} />,
      panel: <SettingsGeneral />,
    },
    {
      name: hasReorganizedFeatureTabs ? "Adoption" : "Usage",
      to: hasReorganizedFeatureTabs ? "adoption" : "usage",
      icon: <StarsIcon height={18} width={18} />,
      panel: <SettingsUsage />,
    },
    {
      name: "Feedback",
      to: "feedback",
      icon: <AutoFeedbackSurveysIcon height={18} width={18} />,
      panel: <SettingsFeedback />,
    },
    {
      name: "Slack",
      to: "slack",
      icon: <RiSlackLine size={18} />,
      panel: <SettingsSlack />,
    },
  ];

  const sections = routes.map((r) => r.to);

  const currentSectionIndex = sections.indexOf(section);
  const currentRoute = routes.find((r) => r.to === section);

  return (
    <>
      <Helmet>
        <title>Feature Settings › {currentRoute?.name ?? ""}</title>
      </Helmet>
      {/*
        This extra wrapper is needed to have Safari not be confused about the height.
        If you put the `flexGrow` on the `Tabs` container, Safari will size Tabs grid-children
        taller than the Tabs grid-container, causing higher than viewport content.
      */}
      <Box display="flex" flexGrow={1}>
        <Tabs
          index={currentSectionIndex}
          orientation="vertical"
          variant="sideMenu"
          w="100%"
          isLazy
          isManual
        >
          <TabList>
            {routes.map((route) => (
              <Tab key={route.to} to={`../${route.to}`}>
                <HStack>
                  {route.icon}
                  <Text>{route.name}</Text>
                </HStack>
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {routes.map((route) => (
              <TabPanel key={route.to}>
                <ErrorBoundary>{route.panel}</ErrorBoundary>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
}
