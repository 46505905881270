import { useMemo } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Box, BoxProps, HStack, Text } from "@chakra-ui/react";

import { FEEDBACK_SOURCE_LABELS } from "@bucketco/shared/constants";
import { FunnelStepList } from "@bucketco/shared/featureAPI";
import {
  FeedbackDTO,
  FeedbackList,
  FeedbackListSortBy,
  FeedbackSource,
} from "@bucketco/shared/feedbackAPI";
import { FeatureUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import {
  DeleteIconButton,
  EditIconButton,
} from "@/common/components/CommonIconButtons";
import CompanyDisplay from "@/common/components/CompanyDisplay";
import { DataTable } from "@/common/components/DataTable/DataTable";
import ExpandableTextCell from "@/common/components/ExpandableTextCell";
import NotAvailableCell from "@/common/components/NotAvailableCell";
import TimestampCell from "@/common/components/TimestampCell";
import { useSafeDataTableStateProps } from "@/common/hooks/useDataTableParamState";
import { createSortableColumnHelper } from "@/common/types/reactTableHelpers";
import { FeatureDisplay } from "@/feature/components/FeatureDisplay";
import { starsFunnelStateDescriptions } from "@/feature/data/starsFunnelDescriptions";
import DeleteFeedbackDialog from "@/feedback/components/DeleteFeedbackDialog";
import { FeedbackEmptyState } from "@/feedback/components/FeedbackEmptyState";
import { FeedbackFilters } from "@/feedback/components/FeedbackFilters";
import SatisfactionCell from "@/feedback/components/SatisfactionCell";
import { useFeedbackTableState } from "@/feedback/hooks/useFeedbackTableState";

const cellVerticalAdjust: BoxProps = {
  display: "inline-flex",
  alignItems: "center",
  minH: 7,
};

type Props = {
  data?: FeedbackList;
  isLoading?: boolean;
  isFetching?: boolean;
  context?: "root" | "company";
};

export const FeedbackTable = ({
  data,
  isLoading,
  isFetching,
  context = "root",
}: Props) => {
  const [searchParams] = useSearchParams();
  const { currentEnv } = useAuthContext();

  const tableState = useFeedbackTableState();
  // We don't want to pass along invalid props to the data table
  const safeTableProps = useSafeDataTableStateProps(tableState);

  const isFiltered =
    tableState.funnelStepsFilter.length < FunnelStepList.length ||
    !!tableState.satisfactionFilter ||
    !!tableState.featureFilter ||
    !!tableState.companyFilter;

  const columnHelper = createSortableColumnHelper<
    FeedbackDTO,
    FeedbackListSortBy
  >();
  const columns = useMemo(
    () => [
      columnHelper.accessor("score", {
        header: "Score",
        cell: (cell) => {
          const value = cell.getValue();
          return (
            <Box {...cellVerticalAdjust}>
              {value !== null ? (
                <SatisfactionCell value={value} />
              ) : (
                <NotAvailableCell />
              )}
            </Box>
          );
        },
      }),
      columnHelper.accessor("question", {
        header: "Question",
        enableSorting: false,
        cell: (cell) => {
          const value = cell.getValue();
          return (
            <Box {...cellVerticalAdjust}>
              {value ? (
                <ExpandableTextCell value={value} />
              ) : (
                <Text color="dimmed">No question</Text>
              )}
            </Box>
          );
        },
      }),
      columnHelper.accessor("comment", {
        header: "Comment",
        cell: (cell) => {
          const value = cell.getValue();
          return (
            <Box {...cellVerticalAdjust}>
              {value ? (
                <ExpandableTextCell value={value} />
              ) : (
                <Text color="dimmed">No comment</Text>
              )}
            </Box>
          );
        },
        minSize: 280,
      }),
      columnHelper.accessor("userName", {
        header: "User",
        cell: ({ row }) => (
          <Box {...cellVerticalAdjust}>
            <Text fontSize="sm" noOfLines={1}>
              {row.original.userName || row.original.userId}
            </Text>
          </Box>
        ),
      }),
      columnHelper.accessor("companyName", {
        header: "Company",
        cell: ({ row }) => (
          <Box {...cellVerticalAdjust}>
            {row.original.companyId ? (
              <CompanyDisplay
                company={{
                  id: row.original.companyId,
                  name: row.original.companyName,
                }}
                link
              />
            ) : (
              <NotAvailableCell />
            )}
          </Box>
        ),
      }),
      columnHelper.accessor("companyFunnelStep", {
        header: "STARS state",
        sortDescFirst: true,
        cell: (cell) => {
          const value = cell.getValue();
          const description = starsFunnelStateDescriptions.find(
            ({ id }) => id === value,
          );
          return description ? (
            <HStack spacing={2} {...cellVerticalAdjust}>
              {description.visualization}
              <Text>{description.label}</Text>
            </HStack>
          ) : (
            <NotAvailableCell />
          );
        },
      }),
      columnHelper.accessor("featureName", {
        header: "Feature",
        id: "feature",
        enableSorting: false,
        cell: ({ row }) => (
          <Box {...cellVerticalAdjust}>
            {row.original.featureId ? (
              <Text
                as={Link}
                to={FeatureUrl(currentEnv!, {
                  id: row.original.featureId,
                  name: row.original.featureName || "",
                })}
              >
                <FeatureDisplay
                  feature={{
                    id: row.original.featureId,
                    name: row.original.featureName || "",
                  }}
                />
              </Text>
            ) : (
              <NotAvailableCell />
            )}
          </Box>
        ),
      }),
      columnHelper.accessor("source", {
        header: "Source",
        enableSorting: false,
        cell: (cell) => {
          const value = cell.getValue();
          const name = FEEDBACK_SOURCE_LABELS[value as FeedbackSource];
          if (!name) return <NotAvailableCell />;

          return (
            <Box {...cellVerticalAdjust}>
              <Text>{name}</Text>
            </Box>
          );
        },
      }),
      columnHelper.accessor("timestamp", {
        header: "Received",
        sortDescFirst: true,
        cell: (cell) => {
          const value = cell.getValue();
          return (
            <Box {...cellVerticalAdjust}>
              {value !== undefined ? (
                <TimestampCell value={value} leftAlign />
              ) : (
                <NotAvailableCell />
              )}
            </Box>
          );
        },
      }),
      columnHelper.display({
        header: "Actions",
        id: "actions",
        enableSorting: false,
        cell: ({ row }) => (
          <Box {...cellVerticalAdjust}>
            <HStack spacing={0}>
              <EditIconButton
                label="Edit feedback entry"
                to={{
                  pathname: `edit/${row.original.id}`,
                  search: searchParams.toString(),
                }}
              />
              <DeleteFeedbackDialog feedbackId={row.original.id}>
                {({ openDialog }) => (
                  <DeleteIconButton
                    label="Delete feedback entry"
                    onClick={openDialog}
                  />
                )}
              </DeleteFeedbackDialog>
            </HStack>
          </Box>
        ),
      }),
    ],
    [columnHelper, currentEnv, searchParams],
  );

  const feedback = data?.data || [];

  return (
    <DataTable
      columns={columns}
      data={feedback}
      emptyState={
        <FeedbackEmptyState isFiltered={isFiltered} isLoading={isLoading} />
      }
      isFetching={isFetching}
      meta={data?.metadata}
      pageSize={20}
      tableId={context === "root" ? "feedback" : `${context}-feedback`}
      toolbarLeftActions={
        <FeedbackFilters
          canCreate={context !== "root"}
          canFilterByCompany={context !== "company"}
        />
      }
      totalCount={data?.totalCount ?? 0}
      canCustomize
      canPaginate
      canSort
      {...safeTableProps}
    />
  );
};
