import { Text } from "@chakra-ui/react";
import { chakra, ResponsiveValue } from "@chakra-ui/system";

import { SlackChannel } from "@bucketco/shared/slackConnectionAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import SlackHashLogo from "@/common/assets/slack-hash-logo.svg?react";

const SlackHashIcon = chakra(SlackHashLogo);

type SlackNotificationStatusToggleProps = {
  slackChannel: SlackChannel | null;
  size:
    | ResponsiveValue<(string & object) | "sm" | "md" | "lg" | "xs" | "2xs">
    | undefined;
};

export function SlackNotificationStatus({
  slackChannel,
  size = "sm",
}: SlackNotificationStatusToggleProps) {
  const { currentApp, currentEnv } = useAuthContext();

  const defaultSlackChannel =
    currentEnv?.slackChannel ||
    currentApp?.environments.find((env) => env.isProduction)?.slackChannel;

  if (!slackChannel && !defaultSlackChannel) {
    return null;
  }

  return (
    <Text fontSize={size} fontWeight="normal">
      <SlackHashIcon
        boxSize={3}
        display="inline-block"
        verticalAlign="middle"
      />{" "}
      <Text as="span">Updates will be shared in</Text>{" "}
      <Text as="span" display="inline-block" fontWeight="semibold">
        {slackChannel?.name ?? defaultSlackChannel?.name}
      </Text>
    </Text>
  );
}
