import { keepPreviousData, useQuery } from "@tanstack/react-query";

import { UserFilter } from "@bucketco/shared/filter";
import { UsersQuery } from "@bucketco/shared/userAPI";

import api from "@/common/utils/api";
import { useCurrentEnv } from "../hooks/useCurrentEnv";

import { commonQueryKeys } from "./commonQueryKeys";

type InternalQuery = Omit<UsersQuery, "envId"> & {
  queryKey?: readonly unknown[];
  enabled?: boolean;
};

function useUsersInternal({ queryKey, enabled, ...query }: InternalQuery = {}) {
  const { appId, envId } = useCurrentEnv();

  return useQuery({
    queryKey: queryKey ?? commonQueryKeys.users(appId, envId, query),
    queryFn: () =>
      api
        .get<"/apps/:appId/users">(`/apps/${appId}/users`, {
          params: { ...query, envId: envId! },
        })
        .then((res) => res.data),
    enabled: !!appId && !!envId && enabled,
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    placeholderData: keepPreviousData,
  });
}

export const useUsers = (query?: Omit<UsersQuery, "envId">) =>
  useUsersInternal(query);

export function useUsersSearch(
  search?: string,
  query: Omit<UsersQuery, "envId" | "filter"> = {},
) {
  const { data, ...rest } = useUsersInternal({
    ...query,
    filter: search
      ? ({
          type: "group",
          operator: "or",
          filters: ["id", "name", "email"].map((field) => ({
            type: "userAttribute",
            field,
            operator: "CONTAINS",
            values: [search],
          })),
        } satisfies UserFilter)
      : undefined,
  });

  return { data: data?.data, ...rest };
}

export function useUser(userId?: string) {
  const { appId, envId } = useCurrentEnv();
  const { data, ...rest } = useUsersInternal({
    queryKey: commonQueryKeys.user(appId, envId, userId),
    enabled: !!userId,
    filter: userId
      ? ({
          type: "userAttribute",
          field: "id",
          operator: "IS",
          values: [userId],
        } satisfies UserFilter)
      : undefined,
  });

  return { data: data?.data[0], ...rest };
}
