import { useEffect, useState } from "react";
import { RiArrowRightSLine, RiSearchLine } from "react-icons/ri";
import {
  Badge,
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { useOrgsData } from "@/app/data/useOrgsData";
import { useAuthContext } from "@/auth/contexts/authContext";
import { highlightStringMatch } from "@/common/components/Autocomplete/AutocompleteSelect";
import OrgLogo from "@/common/components/OrgLogo";
import pluralize from "@/common/utils/pluralize";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";

function ListOrgs() {
  const { currentOrg, setActiveOrg } = useAuthContext();
  const { data: orgReq } = useOrgsData();
  const data = orgReq?.orgs ?? [];

  const [searchValue, setSearchValue] = useState<string>("");
  const searchIconColor = useColorModeValue("gray.400", "gray.600");

  const orgs =
    searchValue !== ""
      ? data.filter((i) =>
          i.name.toLowerCase().includes(searchValue.toLowerCase()),
        )
      : data;

  return (
    <>
      <InputGroup>
        <InputLeftElement
          color={!searchValue ? searchIconColor : undefined}
          pointerEvents="none"
        >
          <RiSearchLine size={16} />
        </InputLeftElement>
        <Input
          autoComplete="off"
          placeholder="Search.."
          type="search"
          value={searchValue}
          autoFocus
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </InputGroup>
      <List my={4} spacing={2}>
        {orgs.map((org) => (
          <ListItem
            key={org.id}
            alignItems="center"
            as={Button}
            display="flex"
            justifyContent="space-between"
            leftIcon={
              <OrgLogo mr={1} name={org.name} size="xs" url={org.logoUrl} />
            }
            pl={1.5}
            size="md"
            variant="subtle"
            width="full"
            onClick={() => setActiveOrg(org.id)}
          >
            <HStack flexGrow={1} spacing={4}>
              <Text fontWeight="semibold" isTruncated>
                {searchValue
                  ? highlightStringMatch(org.name, searchValue)
                  : org.name}
              </Text>
              <Text color="gray.500" fontSize="sm" fontWeight="normal">
                {`${org.apps.length} ${pluralize("app", org.apps.length)}`}
              </Text>
            </HStack>
            <HStack flexShrink={0}>
              {currentOrg?.id === org.id ? (
                <Badge colorScheme="brand">Current</Badge>
              ) : null}
              {org.suspendedAt !== null ? (
                <Badge colorScheme="red">Suspended</Badge>
              ) : null}
              <RiArrowRightSLine />
            </HStack>
          </ListItem>
        ))}
      </List>
    </>
  );
}

export default function OrganizationSwitcher({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  useEffect(() => {
    if (isOpen) {
      segmentAnalytics.track("Org Switcher Opened");
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} size="md" onClose={onClose}>
      <ModalOverlay />
      <ModalContent minH="xs" p={0}>
        <ModalCloseButton m={1} />
        <ModalBody display="flex" flexDirection="column" p={0}>
          <Heading fontSize="lg" fontWeight="semibold" mx={6} my={6}>
            Switch organization
          </Heading>
          <Divider />
          <Box p={4}>
            <ListOrgs />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
