import { useQuery } from "@tanstack/react-query";

import { FeatureNamesQueryType } from "@bucketco/shared/featureAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";

import { featureQueryKeys } from "./featureQueryKeys";

export function useFeatureNamesData(
  searchParams: Partial<FeatureNamesQueryType> = {},
  opts?: { appId?: string; retry?: boolean; enabled?: boolean },
) {
  const { appId: currentAppId } = useCurrentEnv();
  const appId = opts?.appId || currentAppId;

  // Avoid literal undefined as it becomes the string 'undefined' in search params
  const cleanSearchParams: Partial<FeatureNamesQueryType> = Object.fromEntries(
    Object.entries(searchParams).filter(
      ([_key, value]) => typeof value !== "undefined",
    ),
  );

  const dataUrl = `/apps/${appId}/features/names?${new URLSearchParams(
    cleanSearchParams as Record<string, string>,
  ).toString()}`;

  return useQuery({
    queryKey: featureQueryKeys.listNames(appId, cleanSearchParams),

    queryFn: () =>
      api.get<"/apps/:appId/features/names">(dataUrl).then((res) => res.data),
    enabled: !!appId && opts?.enabled !== false,
    staleTime: 5 * 60 * 1000,
    refetchInterval(query) {
      if (opts?.retry === true) {
        if (!query.state.data?.length) {
          return 500;
        }
      }
      return false;
    },
  });
}
