import { createContext, useContext } from "react";
import { useParams } from "react-router-dom";

import { useAuthContext } from "@/auth/contexts/authContext";

type EnvironmentContextType = {
  envId?: string;
};

const EnvironmentContext = createContext<EnvironmentContextType>({
  envId: undefined,
});

export function EnvironmentProvider({
  envId,
  children,
}: {
  envId: string;
  children: React.ReactNode;
}) {
  return (
    <EnvironmentContext.Provider value={{ envId }}>
      {children}
    </EnvironmentContext.Provider>
  );
}

// This hook is used to get the current environment and app from the URL params.
// This is a temporary solution until we have a better way to handle this.
// TODO ENV -- remove this hook when we have a better way to handle this
// This is now the most permanent temporary solution...
export function useCurrentEnv() {
  const { envId: envIdOverride } = useContext(EnvironmentContext);
  const { currentEnv, currentApp } = useAuthContext();
  const { appId, envId } = useParams();

  const queryArgs = new URLSearchParams(window.location.search);

  const actualEnvId =
    envIdOverride || queryArgs.get("envId") || envId || currentEnv?.id;
  const actualAppId = appId || currentApp?.id;

  return { envId: actualEnvId, appId: actualAppId };
}
