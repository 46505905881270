import { useMemo } from "react";
import {
  RiChat1Line,
  RiCheckboxCircleFill,
  RiCloseCircleFill,
} from "react-icons/ri";
import { useNavigate } from "react-router";
import { Flex, HStack, Text } from "@chakra-ui/react";

import {
  CompanyFeatureDTO,
  CompanyFeaturesList,
  CompanyFeaturesSortBy,
} from "@bucketco/shared/companyAPI";
import { FeatureUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import CompactNumber from "@/common/components/CompactNumber";
import { DataTable } from "@/common/components/DataTable/DataTable";
import { EvaluationCell } from "@/common/components/EvaluationCell";
import { FrequencyProgress } from "@/common/components/FrequencyProgress";
import InfoIconTooltip from "@/common/components/InfoIconTooltip";
import NotAvailableCell from "@/common/components/NotAvailableCell";
import { RecentActivity } from "@/common/components/RecentActivity";
import TimestampCell from "@/common/components/TimestampCell";
import { useSafeDataTableStateProps } from "@/common/hooks/useDataTableParamState";
import { useFeature } from "@/common/hooks/useFeatureFlags";
import { createSortableColumnHelper } from "@/common/types/reactTableHelpers";
import { linkClickModifierHandler } from "@/common/utils/linkClickHandler";
import { CompanyFeaturesEmptyState } from "@/company/components/CompanyFeaturesEmptyState";
import { useCompanyFeaturesTableState } from "@/company/hooks/useCompanyTableStates";
import { FeatureNameCell } from "@/feature/components/FeatureNameCell";
import { FeatureViewSelect } from "@/feature/components/FeatureViewSelect";
import { TableDisplayModePicker } from "@/feature/components/TableDisplayModePicker";
import { starsFunnelStateDescriptions } from "@/feature/data/starsFunnelDescriptions";
import { useFeaturesSearch } from "@/feature/hooks/useFeaturesSearch";
import { FeedbackSatisfactionIcon } from "@/feedback/components/FeedbackSatisfaction";

type Props = {
  data?: CompanyFeaturesList;
  isLoading?: boolean;
  isFetching?: boolean;
};

export function CompanyFeaturesTable({ data, isLoading, isFetching }: Props) {
  const { currentEnv } = useAuthContext();
  const navigate = useNavigate();

  const tableState = useCompanyFeaturesTableState();
  // We don't want to pass along invalid props to the data table
  const safeTableProps = useSafeDataTableStateProps(tableState);

  const columnHelper = createSortableColumnHelper<
    CompanyFeatureDTO,
    CompanyFeaturesSortBy
  >();

  const { isEnabled: hasReorganizedFeatureTabs } = useFeature(
    "reorganized-feature-tabs",
  );

  const columns = useMemo(() => {
    return [
      columnHelper.accessor("name", {
        header: "Feature",
        cell: FeatureNameCell,
      }),
      columnHelper.accessor("evaluationResult", {
        header: () => (
          <HStack>
            <span>Enabled</span>
            <InfoIconTooltip text="Indicates if company currently matches feature targeting rules. When a timestamp is shown it indicates when targeting first matched." />
          </HStack>
        ),
        cell: (cell) => {
          if (
            cell.row.original.evaluationResult === true &&
            cell.row.original.firstPositiveEvalTime
          ) {
            return (
              <HStack spacing={1}>
                <EvaluationCell value={cell.getValue()} />
                <TimestampCell
                  color="dimmed"
                  value={cell.row.original.firstPositiveEvalTime}
                />
              </HStack>
            );
          }
          return <EvaluationCell value={cell.getValue()} />;
        },
      }),
      columnHelper.accessor("lastCheckTime", {
        header: () => (
          <HStack>
            <span>Last check</span>
            <InfoIconTooltip text="Last code check targeting result" />
          </HStack>
        ),
        cell: ({ row }) => {
          const { lastCheckTime, lastCheckResult } = row.original;
          if (!lastCheckTime) return <NotAvailableCell />;
          return (
            <HStack>
              <HStack spacing={1}>
                {lastCheckResult ? (
                  <RiCheckboxCircleFill color="green" size="16" />
                ) : (
                  <RiCloseCircleFill color="gray" size="16" />
                )}
                <span>{lastCheckResult ? "Enabled" : "Disabled"}</span>
              </HStack>
              <TimestampCell color="dimmed" value={lastCheckTime} />
            </HStack>
          );
        },
      }),
      columnHelper.accessor("createdAt", {
        header: "Created",
        cell: (cell) => {
          const value = cell.getValue();
          return <TimestampCell value={value} />;
        },
      }),
      columnHelper.accessor("satisfaction", {
        header: "Satisfaction",
        cell: ({ row }) =>
          row.original.satisfaction !== null ? (
            <FeedbackSatisfactionIcon
              value={row.original.satisfaction}
              colored
            />
          ) : (
            <NotAvailableCell />
          ),
      }),
      columnHelper.accessor("feedbackCount", {
        header: "Feedback",
        cell: ({ row }) =>
          row.original.feedbackCount > 0 ? (
            <Flex
              alignItems="center"
              aria-label={`${row.original.feedbackCount} submissions`}
              color="gray.500"
              gap={1}
              minW="60px"
            >
              <RiChat1Line size="16" />
              <CompactNumber value={row.original.feedbackCount} />
            </Flex>
          ) : (
            <NotAvailableCell />
          ),
      }),
      columnHelper.accessor("funnelStep", {
        header: `STARS state`,
        sortDescFirst: true,
        cell: (cell) => {
          const value = cell.getValue();
          const description = starsFunnelStateDescriptions.find(
            ({ id }) => id === value,
          );

          return description ? (
            <HStack spacing={2}>
              {description.visualization}
              <Text>{description.label}</Text>
              {value}
            </HStack>
          ) : (
            <NotAvailableCell />
          );
        },
      }),
      columnHelper.accessor("firstUsed", {
        header: "First used",
        sortDescFirst: true,
        cell: (cell) => {
          const value = cell.getValue();
          return value === null ? (
            <NotAvailableCell />
          ) : (
            <TimestampCell value={value} leftAlign />
          );
        },
      }),
      columnHelper.accessor("lastUsed", {
        header: "Last used",
        sortDescFirst: true,
        cell: (cell) => {
          const value = cell.getValue();
          return value === null ? (
            <NotAvailableCell />
          ) : (
            <TimestampCell value={value} leftAlign />
          );
        },
      }),
      columnHelper.accessor("frequency", {
        header: "Frequency",
        cell: (cell) => {
          return cell.row.original.source === "event" &&
            cell.row.original.funnelStep === "retained" ? (
            <FrequencyProgress frequency={cell.getValue()} />
          ) : (
            <NotAvailableCell />
          );
        },
      }),
      columnHelper.accessor("usage", {
        header: hasReorganizedFeatureTabs
          ? "Recent tracking (30d)"
          : "Recent activity (30d)",
        enableSorting: false,
        cell: ({ row }) => (
          <RecentActivity
            data={row.original.usage.map((usage) => ({
              day: usage.date,
              eventCount: usage.eventCount,
            }))}
            mergeActive={row.original.source === "attribute"}
            showAmplitude={row.original.source === "event"}
            showEventCount={row.original.source === "event"}
          />
        ),
      }),
      columnHelper.accessor("eventCount", {
        header: "Total events",
        cell: ({ row }) =>
          row.original.source === "attribute" ? (
            <NotAvailableCell />
          ) : (
            <>
              <CompactNumber value={row.original.eventCount} />
            </>
          ),
      }),
    ];
  }, [columnHelper, hasReorganizedFeatureTabs]);

  const features = data?.data ?? [];
  const filteredFeatures = useFeaturesSearch(
    features,
    {
      enabled: true,
      predicate: (f) => {
        return (
          f.id === tableState.searchQuery ||
          f.key.toLowerCase().includes(tableState.searchQuery.toLowerCase()) ||
          f.name.toLowerCase().includes(tableState.searchQuery.toLowerCase())
        );
      },
    },
    [tableState.searchQuery],
  );

  return (
    <DataTable
      columns={columns}
      data={filteredFeatures}
      emptyState={<CompanyFeaturesEmptyState isLoading={isLoading} />}
      isFetching={isFetching}
      meta={data?.metadata}
      searchPlaceholder="Search by name, key, or ID"
      tableId="company-features"
      toolbarLeftActions={<FeatureViewSelect size="sm" variant="outline" />}
      toolbarRightActions={<TableDisplayModePicker />}
      variant="clickable"
      canCustomize
      canSearch
      canSort
      onRowClick={(row, e) => {
        const url = FeatureUrl(currentEnv!, row.original);
        e.preventDefault();

        linkClickModifierHandler(url, () => {
          navigate(url);
        })(e);
      }}
      {...safeTableProps}
    />
  );
}
