import { appendErrors, FieldError } from "react-hook-form";
import { AxiosError } from "axios";
import { ZodIssue } from "zod";

import { ErrorResponse } from "@bucketco/shared/api";

/**
 * Convert Zod errors to React-hook-form errors
 *
 * Adapted from https://github.com/react-hook-form/resolvers/blob/259b4dcf128aba909e1108b269c96dca6d5be9b9/zod/src/zod.ts
 *
 * @see [HookForm Zod resolver](https://github.com/react-hook-form/resolvers#zod)
 *
 */
export function convertZodErrorsToHookFormErrors(
  zodErrors: ZodIssue[] = [],
  options: {
    criteriaMode: "firstError" | "all";
  } = { criteriaMode: "firstError" },
): Record<string, FieldError> {
  const errorQueue = zodErrors.slice();
  const validateAllFieldCriteria = options.criteriaMode === "all";

  const errors: Record<string, FieldError> = {};
  for (; errorQueue.length; ) {
    const error = errorQueue[0];
    const { code, message, path } = error;
    const _path = path.join(".");

    if (!errors[_path]) {
      if ("unionErrors" in error) {
        const unionError = error.unionErrors[0].errors[0];

        errors[_path] = {
          message: unionError.message,
          type: unionError.code,
        };
      } else {
        errors[_path] = { message, type: code };
      }
    }

    if ("unionErrors" in error) {
      error.unionErrors.forEach((unionError) =>
        unionError.errors.forEach((e) => errorQueue.push(e)),
      );
    }

    if (validateAllFieldCriteria) {
      const types = errors[_path].types;
      const messages = types && types[error.code];

      errors[_path] = appendErrors(
        _path,
        validateAllFieldCriteria,
        errors,
        code,
        messages
          ? ([] as string[]).concat(messages as string[], error.message)
          : error.message,
      ) as FieldError;
    }

    errorQueue.shift();
  }

  return errors;
}

export function translateError(
  error: AxiosError<ErrorResponse> | Error | any,
  title?: string | ((error: AxiosError<ErrorResponse>) => string),
) {
  const errorResponse =
    "name" in error && error.name === "AxiosError" && error.response
      ? error.response.data
        ? { ...error.response.data, status: error.response.status }
        : {
            status: error.response.status || 500,
            error: {
              message: "Unknown error",
              code: "unknown_error",
            },
            validationErrors: [],
          }
      : error instanceof Error
      ? {
          status: 500,
          error: {
            message: error.message,
            code: "unknown_error",
          },
          validationErrors: [],
        }
      : {
          status: 500,
          error: {
            message: error?.message || "Unknown error",
            code: "unknown_error",
          },
        };

  const individualErrors = errorResponse.validationErrors?.length
    ? convertZodErrorsToHookFormErrors(errorResponse.validationErrors)
    : [];

  let prefix = "Error";
  switch (errorResponse.status) {
    case 401:
      prefix = "Unauthorized";
      break;
    case 403:
      prefix = "Forbidden";
      break;
    case 404:
      prefix = "Not found";
      break;
    case 409:
      prefix = "Conflict detected";
      break;
    case 400:
      prefix = "Bad input data";
      break;
    case 500:
      prefix = "Server error";
      break;
    default:
      break;
  }

  const description = `${prefix}: ${
    errorResponse.error?.message ||
    error.message ||
    "An unexpected error has occurred"
  }`;

  const actualTitle =
    (typeof title === "function" ? title(error) : title) ||
    "There was a problem with your request";

  return {
    title: actualTitle,
    description,
    fieldErrors: individualErrors,
    status: errorResponse.status,
  };
}
