import { ReactNode } from "react";
import { Box, Center, useColorModeValue, VStack } from "@chakra-ui/react";

import BucketLogo from "@/common/assets/bucket-logo-currentcolor.svg?react";

import { AppFooter } from "./AppFooter";

export function InitLayout({ children }: { children: ReactNode }) {
  const logoColor = useColorModeValue("gray.900", "white");
  const bgColor = useColorModeValue("white", "gray.900");

  return (
    <VStack align="stretch" bgColor={bgColor} h="100vh" m={0} spacing={0}>
      <Box color={logoColor} left={6} position="absolute" top={6}>
        <BucketLogo width="20" />
      </Box>

      <Center flex="1" px={6} py={16}>
        {children}
      </Center>

      <Box alignSelf="start">
        <AppFooter />
      </Box>
    </VStack>
  );
}
