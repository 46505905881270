import { IconBaseProps } from "react-icons";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  BoxProps,
  Flex,
  FlexProps,
  Link,
  LinkProps,
} from "@chakra-ui/react";
import omit from "lodash/omit";

import { SegmentDTO } from "@bucketco/shared/segmentAPI";
import { SegmentUrl } from "@bucketco/shared/urls";
import { getFraction } from "@bucketco/shared/utils/getFraction";

import { useAuthContext } from "@/auth/contexts/authContext";
import { SimplePieChart } from "@/common/charts/components/SimplePieChart";
import { highlightStringMatch } from "@/common/components/Autocomplete/AutocompleteSelect";
import { useSegment } from "../data/useSegments";

type FlagDisplayProps = {
  segment: Pick<SegmentDTO, "id" | "name">;
  highlight?: string;
  iconProps?: BoxProps & IconBaseProps;
} & (
  | ({ link: true } & Omit<LinkProps, "size">)
  | ({ link?: false | undefined } & FlexProps)
);

export function SegmentDisplay({
  segment,
  highlight,
  iconProps,
  ...containerProps
}: FlagDisplayProps) {
  const { currentEnv } = useAuthContext();
  const { data } = useSegment(segment.id);

  const sharedProps = {
    display: "inline-flex",
    alignItems: "center",
    gap: 1,
  };

  const content = (
    <>
      <Box as="span" color="dimmed" fontSize="14px" {...iconProps}>
        <SimplePieChart
          value={
            getFraction(data?.segmentCount ?? 1, data?.allCount ?? 1) * 100
          }
        />
      </Box>

      <Box as="span" flexShrink="1" minW="0">
        {highlight
          ? highlightStringMatch(segment.name, highlight)
          : segment.name}
      </Box>
    </>
  );

  if (containerProps.link) {
    return (
      <Link
        _hover={{ textDecoration: "underline" }}
        as={RouterLink}
        textDecoration="none"
        {...sharedProps}
        {...omit(containerProps, "link")}
        to={SegmentUrl(currentEnv!, segment.id)}
      >
        {content}
      </Link>
    );
  } else {
    return (
      <Flex as="span" {...sharedProps} {...containerProps}>
        {content}
      </Flex>
    );
  }
}
