/**
 * Filters and maps an array in a single pass, removing any undefined or null values from the result.
 * Can be deprecated when TypeScript is upgraded past 5.5.4.
 *
 * @template T - The type of elements in the input array
 * @template U - The type of elements in the output array
 *
 * @param array - The input array to filter and map
 * @param map - A function that takes an element, its index, and the original array, and returns either a value or undefined/null
 *
 * @returns An array containing only the non-null and non-undefined values returned by the map function
 *
 * @example
 * ```typescript
 * const numbers = [1, 2, 3, 4, 5];
 * const evenDoubled = filterMap(numbers, n => n % 2 === 0 ? n * 2 : undefined);
 * // Result: [4, 8]
 * ```
 */
export function filterMap<T, U>(
  array: T[],
  map: (value: T, index: number, array: T[]) => U | null | undefined,
): NonNullable<U>[] {
  const result: NonNullable<U>[] = [];
  for (const item of array) {
    const mapped = map(item, result.length, array);
    if (mapped !== null && mapped !== undefined) result.push(mapped);
  }
  return result;
}
