import { Link, To } from "react-router-dom";
import {
  Box,
  chakra,
  HStack,
  StackProps,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { StageNameDTO } from "@bucketco/shared/stageAPI";

import EnvironmentSvg from "@/common/assets/environment-dot-circle-fill.svg?react";
import { useFeatureRolloutStatusData } from "@/feature/data/useFeatureRolloutStatusData";
import { StageDisplayName } from "@/stage/components/StageDisplayName";
import { StageFillIcon } from "@/stage/components/StagesIcon";

const EnvironmentIcon = chakra(EnvironmentSvg);

type RolloutStageLabelProps = {
  stage?: StageNameDTO | null;
};

export const RolloutStageLabel = ({ stage }: RolloutStageLabelProps) => {
  if (!stage) {
    return (
      <Box alignItems="center" display="inline-flex" gap={1}>
        <NoStage />
      </Box>
    );
  }

  return (
    <Box alignItems="center" display="inline-flex" gap={2}>
      <StageDisplayName stage={stage} />
    </Box>
  );
};

type RolloutStageTagProps = {
  featureId?: string;
  link?: To;
};

export const RolloutStageTag = ({ featureId, link }: RolloutStageTagProps) => {
  const { data, isLoading } = useFeatureRolloutStatusData(featureId);

  if (isLoading) {
    return null;
  }

  return (
    <Box as={link ? Link : undefined} fontSize="sm" to={link!}>
      <RolloutStageLabel stage={data?.stage} />
    </Box>
  );
};

export function NoDataYet(props: StackProps) {
  const iconColor = useColorModeValue("gray.400", "gray.500");
  return (
    <HStack {...props} spacing={1}>
      <EnvironmentIcon boxSize={3} color={iconColor} />
      <Text as="span" color="dimmed" fontWeight="medium" whiteSpace="nowrap">
        No data yet
      </Text>
    </HStack>
  );
}

export function NoStage(props: StackProps) {
  const iconColor = useColorModeValue("gray.400", "gray.500");
  return (
    <HStack {...props} spacing={1}>
      <StageFillIcon boxSize={3} color={iconColor} flexShrink={0} />
      <Text as="span" color="dimmed" fontWeight="medium" whiteSpace="nowrap">
        No stage
      </Text>
    </HStack>
  );
}
