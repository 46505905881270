import {
  FieldValues,
  useForm,
  UseFormProps,
  UseFormReturn,
} from "react-hook-form";
import { useToast, UseToastOptions } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { z } from "zod";

import { ErrorResponse } from "@bucketco/shared/api";

import { translateError } from "@/common/utils/errorHandling";

import { useErrorToast } from "./useErrorToast";

type CustomErrorToastTitle =
  | string
  | ((error: AxiosError<ErrorResponse>) => string)
  | undefined;

type FormMutationSubmitHandlerOptions<TResult> = {
  prepareVariables?: (data: any) => any;
  successToast?: string | ((result: TResult) => string);
  errorToast?: CustomErrorToastTitle;
};

function handleFormErrors<TFormFields extends FieldValues>(
  form: UseFormReturn<TFormFields>,
  error: AxiosError<ErrorResponse>,
  toast?: {
    errorToast: (props?: UseToastOptions) => void;
    customErrorToastTitle?: CustomErrorToastTitle;
  },
) {
  const { title, description, fieldErrors, status } = translateError(
    error,
    toast?.customErrorToastTitle,
  );

  if (status === 400) {
    const hasFieldErrors = Object.keys(fieldErrors).length > 0;
    if (hasFieldErrors) {
      Object.entries(fieldErrors).forEach(([fieldName, error]) => {
        form.setError(fieldName as Parameters<typeof form.setError>[0], error);
      });
    } else {
      form.setError("root", { message: description });
    }
  } else if (toast) {
    toast.errorToast({
      title,
      description,
      duration: 5000,
      isClosable: true,
    });
  }
}

export default function useApiForm<TArgs extends FieldValues, TResult>(
  apiHandler: (data: TArgs) => Promise<TResult>,
  argsSchema: z.AnyZodObject | z.ZodTypeAny,
  mutationOptions?: Omit<
    UseMutationOptions<TResult, AxiosError, TArgs>,
    "mutationFn"
  >,
  formOptions?: UseFormProps<TArgs>,
) {
  // form
  const form = useForm<TArgs>({
    resolver: zodResolver(argsSchema),
    mode: "onChange",
    ...formOptions,
  });

  // mutation
  const mutation = useMutation<TResult, AxiosError<ErrorResponse>, TArgs>({
    mutationFn: apiHandler,
    retry: 0,
    ...mutationOptions,
  });

  const { mutateAsync } = mutation;

  const handleSubmit = form.handleSubmit((data) =>
    mutateAsync(data)
      .then(() => {
        form.clearErrors();
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        form.clearErrors();

        handleFormErrors(form, err);
      }),
  );

  return {
    form,
    handleSubmit,
    mutation,
  };
}

export function useFormMutationSubmitHandler<
  TFormFields extends FieldValues,
  TValue,
  TVariables = TFormFields,
>(
  form: UseFormReturn<TFormFields>,
  mutation: UseMutationResult<TValue, AxiosError<ErrorResponse>, TVariables>,
  onSuccess?: (result: TValue, variables: TFormFields) => void,
  options?: FormMutationSubmitHandlerOptions<TValue>,
) {
  const toast = useToast();
  const errorToast = useErrorToast();

  return form.handleSubmit(async (data) => {
    form.clearErrors();

    return mutation.mutateAsync(
      options?.prepareVariables
        ? options.prepareVariables(data)
        : (data as unknown as TVariables),
      {
        onSuccess(result) {
          const title =
            typeof options?.successToast === "function"
              ? options?.successToast(result)
              : options?.successToast;

          if (title) {
            toast({
              title,
              status: "success",
              duration: 2000,
              isClosable: true,
            });
          }
          onSuccess?.(result, data);
        },
        onError(error) {
          form.reset(undefined, {
            keepDirty: true,
            keepValues: true,
          });

          handleFormErrors(form, error, {
            errorToast,
            customErrorToastTitle: options?.errorToast,
          });
        },
      },
    );
  });
}
