import { z } from "zod";

import { nameSchema } from "./schemas/nameSchema";
import { APIEmptyResponse, APIResponse } from "./api";
import { EnvironmentDTO } from "./environmentAPI";

export type AppDTO = {
  id: string;
  name: string;
  demo: boolean;
  environments: EnvironmentDTO[];
};

export const CreateAppArgsSchema = z
  .object({
    name: nameSchema,
    organizationId: z.string(),
    demoData: z.boolean().default(false),
  })
  .strict();

export const CreateAppWithOrgArgsSchema = z
  .object({
    name: nameSchema,
  })
  .strict();

export type CreateAppArgsType = z.input<typeof CreateAppArgsSchema>;

export const PatchAppArgsSchema = CreateAppArgsSchema.pick({
  name: true,
}).partial();

export type PatchAppArgsType = z.infer<typeof PatchAppArgsSchema>;

export interface AppAPI {
  "/apps": {
    POST: {
      response: APIResponse<{
        app: AppDTO;
      }>;
      body: CreateAppArgsType;
    };
  };
  "/apps/:appId": {
    PATCH: {
      response: APIResponse<{
        app: AppDTO;
      }>;
      params: {
        appId: string;
      };
      body: PatchAppArgsType;
    };
    DELETE: {
      response: APIEmptyResponse;
      params: {
        appId: string;
      };
    };
  };
}
