import { z } from "zod";

import { nameSchema } from "./schemas/nameSchema";
import { APIEmptyResponse, APIResponse } from "./api";
import { CreateFlagVersionSchema, FlagVersionTargeting } from "./flagAPI";

// Post & Patch DTO

export const StagePostSchema = z
  .object({
    name: nameSchema.max(64),
    colorIndex: z.number().min(0).max(8),
    order: z.number().optional(),
    stageEnvironments: z.array(CreateFlagVersionSchema).refine(
      (envs) => {
        const environmentIds = envs.flatMap(({ environmentId }) =>
          environmentId ? [environmentId] : [],
        );
        return new Set(environmentIds).size === environmentIds.length;
      },
      { message: "Duplicate environmentIds are not allowed" },
    ),
  })
  .strict();

export type StagePostArgs = z.input<typeof StagePostSchema>;
export type StagePostArgsOutput = z.output<typeof StagePostSchema>;

export const StagePatchSchema = StagePostSchema.partial().strict();
export type StagePatchArgs = z.input<typeof StagePatchSchema>;

export const StageDeleteSchema = z.object({
  newStageId: z.string().optional(),
});
export type StageDeleteArgs = z.input<typeof StageDeleteSchema>;

// Model

export type StageEnvironmentDTO = FlagVersionTargeting & {
  id: string;
  environment: {
    id: string;
    isProduction: boolean;
    name: string;
    order: number;
  };
};

export type StageDTO = {
  id: string;
  name: string;
  colorIndex: number;
  order: number;
  featureCount: number;
  createdAt: string;
  stageEnvironments: StageEnvironmentDTO[];
};

export type StageNameDTO = Pick<
  StageDTO,
  "id" | "name" | "colorIndex" | "order"
>;

// Queries

export const StagesSortByColumns = ["name", "order", "createdAt"] as const;
const StagesQuerySortBySchema = z.enum(StagesSortByColumns);
export type StagesQuerySortBy = z.infer<typeof StagesQuerySortBySchema>;

export const StagesQuerySchema = z
  .object({
    sortOrder: z.enum(["asc", "desc"]).default("asc"),
    sortBy: StagesQuerySortBySchema.default("order"),
  })
  .strict();

export type StagesQuery = z.input<typeof StagesQuerySchema>;

// API

export interface StageAPI {
  "/apps/:appId/stages": {
    POST: {
      body: StagePostArgs;
      response: APIResponse<{
        stage: StageDTO;
      }>;
      params: { appId: string };
    };
    GET: {
      response: APIResponse<{
        stages: StageDTO[];
        sortBy: StagesQuerySortBy;
        sortOrder: "asc" | "desc";
      }>;
      params: { appId: string };
      query: StagesQuery;
    };
  };
  "/apps/:appId/stages/:stageId": {
    GET: {
      response: APIResponse<{
        stage: StageDTO;
      }>;
      params: { appId: string; stageId: string };
    };
    PATCH: {
      body: StagePatchArgs;
      response: APIResponse<{
        stage: StageDTO;
      }>;
      params: { appId: string; stageId: string };
    };
    DELETE: {
      body: StageDeleteArgs;
      response: APIEmptyResponse;
      params: { appId: string; stageId: string };
    };
  };
}
