import { useMemo } from "react";
import { Link, Text } from "@chakra-ui/react";

import { UserDTO, UserPage, UsersSortByColumn } from "@bucketco/shared/userAPI";

import CompactNumber from "@/common/components/CompactNumber";
import { DataTable } from "@/common/components/DataTable/DataTable";
import NotAvailableCell from "@/common/components/NotAvailableCell";
import TimestampCell from "@/common/components/TimestampCell";
import { useSafeDataTableStateProps } from "@/common/hooks/useDataTableParamState";
import { createSortableColumnHelper } from "@/common/types/reactTableHelpers";
import { CompanyUsersEmptyState } from "@/company/components/CompanyUsersEmptyState";
import { CompanyUsersFilters } from "@/company/components/CompanyUsersFilters";
import { useCompanyUsersTableState } from "@/company/hooks/useCompanyTableStates";

type Props = {
  data?: UserPage;
  isLoading?: boolean;
  isFetching?: boolean;
};

export function CompanyUsersTable({ data, isLoading, isFetching }: Props) {
  const tableState = useCompanyUsersTableState();
  // We don't want to pass along invalid props to the data table
  const safeTableProps = useSafeDataTableStateProps(tableState);

  const columnHelper = createSortableColumnHelper<UserDTO, UsersSortByColumn>();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("name", {
        header: "Name",

        cell: ({ row }) => <Text>{row.original.name || row.original.id}</Text>,
      }),
      columnHelper.accessor("id", {
        header: "ID",
        cell: (cell) => <Text fontSize="sm">{cell.getValue()}</Text>,
      }),
      columnHelper.accessor("firstSeen", {
        header: "First seen",
        sortDescFirst: true,
        cell: (cell) => {
          const value = cell.getValue();

          if (value) {
            return <TimestampCell value={value} />;
          }

          return <NotAvailableCell />;
        },
      }),
      columnHelper.accessor("lastSeen", {
        header: "Last seen",
        sortDescFirst: true,
        cell: (cell) => {
          const value = cell.getValue();

          if (value) {
            return <TimestampCell value={value} />;
          }

          return <NotAvailableCell />;
        },
      }),
      columnHelper.accessor("eventCount", {
        header: "Event count",
        cell: (cell) => <CompactNumber value={cell.getValue()} />,
      }),
      columnHelper.accessor("email", {
        header: "Email",
        cell: (cell) => {
          const value = cell.getValue();

          if (value) {
            return <Link href={`mailto:${value}`}>{value}</Link>;
          }

          return <NotAvailableCell />;
        },
      }),
    ];
  }, [columnHelper]);

  const users = data?.data || [];

  return (
    <DataTable
      columns={columns}
      data={users}
      emptyState={<CompanyUsersEmptyState isLoading={isLoading} />}
      isFetching={isFetching}
      meta={data?.metadata}
      pageSize={50}
      tableId="company-users"
      toolbarLeftActions={<CompanyUsersFilters />}
      totalCount={data?.totalCount ?? 0}
      canCustomize
      canPaginate
      canSort
      {...safeTableProps}
    />
  );
}
