import {
  CompaniesQuery,
  CompanyFeaturesQuery,
  CompanyFeatureUsersQuery,
} from "@bucketco/shared/companyAPI";

const qk = {
  list: (
    appId?: string,
    envId?: string,
    params?: Omit<CompaniesQuery, "envId">,
  ) =>
    [
      "apps",
      appId,
      "environments",
      envId,
      "companies",
      ...(params ? [params] : []),
    ] as const,

  single: (appId?: string, envId?: string, companyId?: string) =>
    [...qk.list(appId, envId), companyId] as const,

  features: (
    appId?: string,
    envId?: string,
    companyId?: string,
    params?: Omit<CompanyFeaturesQuery, "envId">,
  ) =>
    [
      ...qk.single(appId, envId, companyId),
      "features",
      ...(params ? [params] : []),
    ] as const,

  featureUsers: (
    appId?: string,
    envId?: string,
    companyId?: string,
    featureId?: string,
    params?: Omit<CompanyFeatureUsersQuery, "envId">,
  ) =>
    [
      ...qk.single(appId, envId, companyId),
      "feature-users",
      featureId,
      ...(params ? [params] : []),
    ] as const,

  attributes: (appId?: string, envId?: string) =>
    [...qk.list(appId, envId), "attributes"] as const,

  companyNames: (
    appId?: string,
    envId?: string,
    params: Record<string, any> = {},
  ) =>
    [...qk.list(appId, envId), "names", ...(params ? [params] : [])] as const,
};

export { qk as companyQueryKeys };
