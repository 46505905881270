import { forwardRef, useMemo } from "react";
import { Flex, HStack, Text } from "@chakra-ui/react";

import { CompanyFeatureDTO } from "@bucketco/shared/companyAPI";

import AutocompleteSelect, {
  getStringFilterFunction,
} from "@/common/components/Autocomplete/AutocompleteSelect";
import NotAvailableCell from "@/common/components/NotAvailableCell";
import {
  AutocompleteImplementationProps,
  AutocompleteValueIdProps,
} from "@/common/utils/autocomplete";
import { useCompanyFeaturesData } from "@/company/data/useCompanyFeaturesData";
import { FeatureDisplay } from "@/feature/components/FeatureDisplay";
import { starsFunnelStateDescriptions } from "@/feature/data/starsFunnelDescriptions";

export type CompanyFeatureAutocompleteSelectProps =
  AutocompleteImplementationProps<CompanyFeatureDTO> &
    AutocompleteValueIdProps<CompanyFeatureDTO> & {
      companyId: string;
    };

export const CompanyFeatureAutocompleteSelect = forwardRef<
  HTMLButtonElement,
  CompanyFeatureAutocompleteSelectProps
>(function CompanyFeatureAutocompleteSelect(
  {
    companyId,
    valueAsId,
    value: valueProp,
    placeholder = "Select a feature",
    canClear,
    onChange,
    ...autocompleteProps
  }: CompanyFeatureAutocompleteSelectProps,
  ref,
) {
  const { data, isLoading, isFetching } = useCompanyFeaturesData(companyId);
  const features = useMemo(() => data?.data ?? [], [data]);

  const valueId = useMemo(
    () => (valueAsId ? valueProp : valueProp?.id),
    [valueAsId, valueProp],
  );

  const internalValue = useMemo(
    () => features.find((f) => f.id === valueId),
    [features, valueId],
  );

  const featureSuggestions = internalValue
    ? [internalValue, ...features.filter((f) => f.id !== internalValue.id)]
    : features;

  return (
    <AutocompleteSelect
      ref={ref}
      canClear={canClear}
      isLoading={isLoading}
      isLoadingSuggestions={isFetching}
      itemFilterFn={getStringFilterFunction("name")}
      itemKeyProperty="id"
      itemRenderFn={(item, search) => {
        const description = starsFunnelStateDescriptions.find(
          ({ id }) => id === item.funnelStep,
        );

        return (
          <Flex
            alignItems="center"
            gap={1}
            justifyContent="space-between"
            py={0.5}
            w="full"
          >
            <FeatureDisplay feature={item} highlightText={search} size="sm" />
            <Flex>
              {description ? (
                <HStack spacing={2}>
                  {description.visualization}
                  <Text>{description.label}</Text>
                </HStack>
              ) : (
                <NotAvailableCell />
              )}
            </Flex>
          </Flex>
        );
      }}
      loadingText="Loading..."
      placeholder={placeholder}
      suggestions={featureSuggestions}
      value={internalValue}
      onChange={(newValue: CompanyFeatureDTO | null) => {
        if (!newValue) {
          if (canClear) onChange?.(null);
          return;
        }
        if (valueAsId) {
          onChange?.(newValue?.id ?? null);
        } else {
          onChange?.(newValue ?? null);
        }
      }}
      onClear={() => {
        if (canClear) onChange?.(null);
      }}
      {...autocompleteProps}
    />
  );
});

CompanyFeatureAutocompleteSelect.displayName =
  "CompanyFeatureAutocompleteSelect";
