import { useMemo } from "react";
import { ButtonGroup, Center, Flex, HStack, Text } from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";

import { WidgetConfigurationType } from "@bucketco/shared/widgetAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import EmptyState from "@/common/components/EmptyState";
import { LoadingSpinner } from "@/common/components/LoadingSpinner";
import MotionBox from "@/common/components/MotionBox";
import PeriodPicker, {
  Period,
  periodList,
} from "@/common/components/PeriodPicker";
import { SubsegmentPicker } from "@/common/components/SubsegmentPicker";
import { useFeature } from "@/common/hooks/useFeatureFlags";
import { useSearchParam } from "@/common/hooks/useSearchParam";
import { EnvironmentDisplayName } from "@/environment/components/EnvironmentDisplayName";
import { FeatureWidgetCreator } from "@/feature/components/FeatureWidgetCreator";
import { UseTargetingSwitch } from "@/feature/components/UseTargetingSwitch";
import { Widget } from "@/widget/components/Widget";
import { useWidgetsData } from "@/widget/data/useWidgetData";

type WidgetsProps = {
  featureId: string;
};

export function FeatureWidgets({ featureId }: WidgetsProps) {
  const { currentEnv } = useAuthContext();
  const [period, setPeriod] = useSearchParam<Period>("period", {
    allowlist: periodList,
    fallback: "past30days",
  });
  const { data: unfilteredWidgets = [], isLoading } = useWidgetsData({
    featureId,
  });

  const { isEnabled: hasReorganizedFeatureTabs } = useFeature(
    "reorganized-feature-tabs",
  );

  const widgets = hasReorganizedFeatureTabs
    ? unfilteredWidgets.filter(
        ({ configuration: { type } }) => type !== "feedback",
      )
    : unfilteredWidgets;

  const allowedTypes = useMemo<WidgetConfigurationType[]>(() => {
    return [
      "featureMetric",
      !widgets.some(({ configuration: { type } }) => type === "starsFunnel") &&
        "starsFunnel",
      !hasReorganizedFeatureTabs &&
        !widgets.some(({ configuration: { type } }) => type === "feedback") &&
        "feedback",
    ].filter(Boolean) as WidgetConfigurationType[];
  }, [hasReorganizedFeatureTabs, widgets]);

  if (isLoading) {
    return (
      <Center flexGrow={1}>
        <LoadingSpinner />
      </Center>
    );
  }

  if (widgets.length === 0) {
    return (
      <Center flexGrow={1}>
        <EmptyState
          action={
            <FeatureWidgetCreator
              allowedTypes={allowedTypes}
              featureId={featureId}
            />
          }
          description={
            <Text color="dimmed" fontSize="sm">
              Tailor your feature to only show metrics and feedback that you
              care about.
            </Text>
          }
          title="No widgets added"
        />
      </Center>
    );
  }

  return (
    <Flex direction="column" gap={4}>
      <Flex align="center" justify="space-between">
        <HStack spacing={3}>
          <ButtonGroup spacing={2}>
            <SubsegmentPicker />
            <PeriodPicker
              value={period}
              onChange={(newPeriod) => setPeriod(newPeriod, { replace: true })}
            />
          </ButtonGroup>
          <UseTargetingSwitch />
        </HStack>
        <ButtonGroup>
          <FeatureWidgetCreator
            allowedTypes={allowedTypes}
            featureId={featureId}
          />
        </ButtonGroup>
      </Flex>
      <Flex direction={"column"} w="full">
        <AnimatePresence initial={false}>
          {widgets.map((widget) => (
            <AnimatedWidget key={widget.id}>
              <Widget
                key={widget.id}
                implicitConfiguration={{
                  featureId,
                }}
                widget={widget}
              />
            </AnimatedWidget>
          ))}
        </AnimatePresence>
      </Flex>
      <HStack fontSize="xs" spacing={1}>
        <Text color="dimmed">Widget data shown for</Text>
        {currentEnv && <EnvironmentDisplayName environment={currentEnv} />}
      </HStack>
    </Flex>
  );
}

export function AnimatedWidget({ children }: { children: React.ReactNode }) {
  return (
    <MotionBox
      animate={{ opacity: 1, height: "auto", scale: 1 }}
      exit={{ opacity: 0, height: 0, scale: 0.95 }}
      initial={{ opacity: 0, height: 0, scale: 0.95 }}
      transition={{ duration: "0.15", ease: "easeInOut" }}
    >
      {children}
    </MotionBox>
  );
}
