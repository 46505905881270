import { popoverAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { cssVar } from "@chakra-ui/theme-tools";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  popoverAnatomy.keys,
);

const $popperBg = cssVar("popper-bg");
const $arrowBg = cssVar("popper-arrow-bg");
const $arrowShadowColor = cssVar("popper-arrow-shadow-color");

export const PopoverTheme = defineMultiStyleConfig({
  baseStyle: {
    content: {
      [$popperBg.variable]: `colors.popoverBackground`,
      [$arrowBg.variable]: $popperBg.reference,
      [$arrowShadowColor.variable]: `colors.popoverBorder`,
      // Redeclare in dark mode for specificity
      _dark: {
        [$popperBg.variable]: `colors.popoverBackground`,
        [$arrowShadowColor.variable]: `colors.popoverBorder`,
      },
      bg: $popperBg.reference,
      boxShadow: "lg",
      borderColor: "popoverBorder",
      _focus: {
        boxShadow: "lg",
      },
    },
  },
  variants: {
    tooltip: {
      body: {
        borderRadius: "md",
        px: 4,
        pt: 3,
        pb: 4,
      },
    },
  },
});
