import { RiAddLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Button, Text } from "@chakra-ui/react";

import { FeatureViewDTO } from "@bucketco/shared/featureViewAPI";

import FeatureIcon from "@/common/assets/feature-icon.svg?react";
import FeatureViewsIcon from "@/common/assets/feature-views-icon.svg?react";
import EmptyState from "@/common/components/EmptyState";
import { useFeature } from "@/common/hooks/useFeatureFlags";

function NewFeatureButton() {
  return (
    <Button
      as={Link}
      leftIcon={<RiAddLine size={16} />}
      size="sm"
      to="new"
      variant="outline"
    >
      New feature
    </Button>
  );
}

export function FeaturesEmptyState({
  view,
  isLoading,
}: {
  view: FeatureViewDTO;
  isLoading?: boolean;
}) {
  const { isEnabled: hasReorganizedFeatureTabs } = useFeature(
    "reorganized-feature-tabs",
  );

  if (!view.isAllFeatureView) {
    return (
      <EmptyState
        description="Use this feature view to group features and customize the table columns to show exactly what you need."
        flexGrow={1}
        icon={<FeatureViewsIcon height="48px" width="48px" />}
        isLoading={isLoading}
        title="No features in view"
      />
    );
  }

  return (
    <EmptyState
      action={<NewFeatureButton />}
      description={
        <Text>
          Features are the building blocks of Bucket - use them for feature
          flagging, {hasReorganizedFeatureTabs ? "adoption" : "usage"} tracking
          and to collect user feedback.
        </Text>
      }
      flexGrow={1}
      icon={<FeatureIcon height="48px" width="48px" />}
      isLoading={isLoading}
      maxW="md"
      title="Define your first feature"
    />
  );
}
