import { Text } from "@chakra-ui/react";

export const SegmentAllRestrictionsWarning = () => (
  <Text as="span" fontSize="xs" lineHeight={1.5}>
    Instead of using the <strong>All</strong> segment select{" "}
    <strong>Everyone</strong> from the targeting mode.
  </Text>
);

export const SegmentStatelessRestrictionsWarning = () => (
  <Text as="span" fontSize="xs" lineHeight={1.5}>
    Segments with <strong>First seen</strong>, <strong>Last seen</strong> or{" "}
    <strong>Feature metric</strong> filters are not supported for targeting
    rules.
  </Text>
);
