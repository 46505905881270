import { useQuery } from "@tanstack/react-query";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import { companyQueryKeys } from "@/company/data/companyQueryKeys";

export function useCompanyNamesData(ids?: string[]) {
  const { appId, envId } = useCurrentEnv();
  return useQuery({
    queryKey: companyQueryKeys.companyNames(appId, envId, ids),
    queryFn: () =>
      api
        .get<`/apps/:appId/company-names`>(`/apps/${appId}/company-names`, {
          params: { ids: ids as [string, ...string[]], envId: envId! },
        })
        .then((res) => res.data),
    enabled: !!appId && !!envId && !!ids?.length,
    placeholderData: (previousData) => {
      return (
        ids?.map((id) => {
          const prevCompany = previousData?.find((c) => c.id === id);
          return {
            name: null,
            ...prevCompany,
            id,
          };
        }) ?? previousData
      );
    },
  });
}
