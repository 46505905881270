import { useEffect, useMemo, useRef } from "react";

import { useFeatureViewsData } from "@/app/data/useFeatureViewsData";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import { useFeatureViewParam } from "@/common/hooks/useFeatureViewParam";

export function useSelectedFeatureView() {
  const errorToast = useErrorToast();
  const [viewId, setViewId] = useFeatureViewParam();
  const notifiedRef = useRef<string | undefined>();

  const { data: featureViews } = useFeatureViewsData();

  const view = useMemo(
    () =>
      featureViews?.find((v) => v.id === viewId) ??
      featureViews?.find((v) => v.isAllFeatureView),
    [viewId, featureViews],
  );

  useEffect(() => {
    if (
      viewId &&
      view &&
      view.id !== viewId &&
      viewId !== notifiedRef.current
    ) {
      errorToast({
        description: `Could not find the feature view, using "${view.name}".`,
        duration: 5000,
      });

      notifiedRef.current = viewId;
      setViewId(view.id);
    } else if (view && view.id === viewId) {
      notifiedRef.current = undefined;
    }
  }, [viewId, view, errorToast, setViewId]);

  return [view, setViewId] as const;
}
