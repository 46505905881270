import { Flex, FlexProps, Heading, HeadingProps, Text } from "@chakra-ui/react";

import ErrorBoundary from "@/common/components/ErrorBoundary";

type HeaderLayoutProps = {
  title?: string | React.ReactElement;
  actions?: React.ReactNode;
  children: React.ReactNode;
  isFixedViewport?: boolean;
  borderless?: boolean;
};

export default function HeaderLayout({
  isFixedViewport = false,
  ...props
}: HeaderLayoutProps & Omit<FlexProps, "title">) {
  if (isFixedViewport) {
    return (
      <Flex flexDir="column" h="100vh">
        <Header {...props} />
        <Flex flexGrow={1} minH={0}>
          <ErrorBoundary>{props.children}</ErrorBoundary>
        </Flex>
      </Flex>
    );
  }

  return (
    <>
      <Header {...props} />
      <ErrorBoundary>{props.children}</ErrorBoundary>
    </>
  );
}

function Header({
  title,
  actions,
  borderless = false,
  ...props
}: HeaderLayoutProps & Omit<FlexProps, "title">) {
  return (
    <Flex
      align="center"
      borderBottomColor="appBorder"
      borderBottomWidth={borderless ? 0 : 1}
      gap={4}
      justifyContent="space-between"
      minH={borderless ? 14 : 18}
      pt={borderless ? 3 : 0}
      px={6}
      {...props}
    >
      <Flex
        align="center"
        flexDirection="row"
        fontSize="xl"
        fontWeight="medium"
        gap={1.5}
        minW="0px"
      >
        {typeof title === "string" ? (
          <HeaderLayoutHeading>{title}</HeaderLayoutHeading>
        ) : (
          title
        )}
      </Flex>
      {actions ?? ""}
    </Flex>
  );
}

export function HeaderLayoutHeading({ children, ...props }: HeadingProps) {
  return (
    <Heading as="h1" fontSize={"lg"} fontWeight="medium" {...props}>
      {children}
    </Heading>
  );
}

export function HeaderLayoutBreadcrumb({ children, ...props }: HeadingProps) {
  return (
    <Text as="span" color="dimmed" fontSize="md" fontWeight="normal" {...props}>
      {children}
    </Text>
  );
}
