import { useQuery } from "@tanstack/react-query";

import { commonQueryKeys } from "@/common/data/commonQueryKeys";
import api from "@/common/utils/api";

export function useInviteData(orgId?: string, inviteKey?: string) {
  return useQuery({
    queryKey: commonQueryKeys.orgInvite(orgId, inviteKey),
    queryFn: () => api.get<"/invite/:inviteKey">(`/invite/${inviteKey}`),
    retry: 0,
    enabled: !!orgId && !!inviteKey,
  });
}
