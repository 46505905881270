// keep this up to date to achieve proper type safety
// when working with the targeting/flagging
export const availableFeatures = [
  "allow-domain-auto-join",
  "expanded-flags-in-features-table",
  "export-manual",
  "export-automatic",
  "demo-app-in-onboarding",
  "simplified-targeting",
  "reorganized-feature-tabs",
  "global",
] as const;

type ArrayToRecord<T extends readonly string[]> = {
  [Key in T[number]]: boolean;
};

export type AvailableFeatures = ArrayToRecord<typeof availableFeatures>;
