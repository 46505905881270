import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useParams } from "react-router-dom";
import { useAuth as useClerkAuth } from "@clerk/clerk-react";

import { AppDTO } from "@bucketco/shared/appAPI";
import { User } from "@bucketco/shared/bootstrapAPI";
import { EnvironmentDTO } from "@bucketco/shared/environmentAPI";
import { Org } from "@bucketco/shared/organizationAPI";

import { useBootstrapData } from "@/auth/data/useBootstrapData";
import { ENVIRONMENT } from "@/common/utils/env";
import navigateToLogin from "@/common/utils/navigateToLogin";

export const DEFAULT_ORG_STORAGE_KEY = "bucket-default-org";
const DEFAULT_APP_STORAGE_KEY = "bucket-default-app";
const DEFAULT_ENV_STORAGE_KEY = "bucket-default-env";
const storedAppKey = (orgId: string) => `${DEFAULT_APP_STORAGE_KEY}.${orgId}`;
const storedEnvKey = (orgId: string) => `${DEFAULT_ENV_STORAGE_KEY}.${orgId}`;

const findActiveAppEnv = (
  currentOrg?: Org,
  chosenEnvId?: string,
): [AppDTO, EnvironmentDTO] | undefined => {
  if (!currentOrg) return undefined;

  const lookupByEnvId = (
    envId?: string,
  ): [AppDTO, EnvironmentDTO] | undefined => {
    if (!currentOrg || !envId) return undefined;
    let env: EnvironmentDTO | undefined;
    const app = currentOrg.apps.find(
      ({ environments }) => (env = environments.find(({ id }) => id === envId)),
    );
    if (!app || !env) return undefined;
    return [app, env];
  };

  const lookupByAppId = (
    appId?: string,
  ): [AppDTO, EnvironmentDTO] | undefined => {
    if (!currentOrg || !appId) return undefined;
    const app = currentOrg.apps.find(({ id }) => id === appId);
    return app ? [app, app.environments[0]] : undefined;
  };

  const storedEnvId =
    localStorage.getItem(storedEnvKey(currentOrg?.id ?? "")) ?? "";

  const storedAppId =
    localStorage.getItem(storedAppKey(currentOrg?.id ?? "")) ?? "";

  return (
    lookupByEnvId(chosenEnvId) ||
    lookupByEnvId(storedEnvId) ||
    lookupByAppId(storedAppId) || [
      currentOrg.apps[0],
      currentOrg.apps[0]?.environments[0],
    ]
  );
};

const setStoredOrg = (orgId: string) => {
  localStorage.setItem(DEFAULT_ORG_STORAGE_KEY, orgId);
};

const setStoredApp = (orgId: string, appId: string) => {
  localStorage.setItem(storedAppKey(orgId), appId);
};

const setStoredEnv = (orgId: string, envId: string) => {
  localStorage.setItem(storedEnvKey(orgId), envId);
};

const clearStoredApp = (orgId?: string) => {
  if (orgId) {
    localStorage.removeItem(storedAppKey(orgId));
    localStorage.removeItem(storedEnvKey(orgId));
  }
};

const clearStoredEnv = (currentApp?: AppDTO, orgId?: string) => {
  if (currentApp && orgId) {
    localStorage.setItem(storedEnvKey(orgId), currentApp.environments[0].id);
  } else if (orgId) {
    localStorage.removeItem(storedEnvKey(orgId));
  }
};

const setActiveOrg = (
  orgId: string,
  options: { refresh: boolean } = { refresh: true },
) => {
  setStoredOrg(orgId);

  if (options.refresh) {
    window.location.assign("/");
  }
};

interface AuthContextType {
  isAuthenticated: boolean;
  isLoading: boolean;
  user?: User;
  currentOrg?: Org;
  currentApp?: AppDTO;
  currentEnv?: EnvironmentDTO;
  setActiveOrg: (orgId: string, options?: { refresh: boolean }) => void;
  clearActiveApp: () => void;
  clearActiveEnv: () => void;
  signOut: (redirectUrl?: string) => Promise<void>;
}

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  isLoading: false,
  setActiveOrg,
  clearActiveApp: () => {},
  clearActiveEnv: () => {},
  signOut: async () => {},
});

const useAuth =
  ENVIRONMENT !== "test"
    ? useClerkAuth
    : () => ({ isLoaded: true, isSignedIn: true, signOut: async () => {} });

export function AuthProvider({ children }: { children: ReactNode }) {
  const currentOrgId = localStorage.getItem(DEFAULT_ORG_STORAGE_KEY);
  const { envId } = useParams();

  const { isLoaded: loadedLoginStatus, isSignedIn, signOut } = useAuth();

  const {
    data,
    isSuccess: bootstrapSuccess,
    isLoading: loadingBootstrap,
    isError: bootstrapError,
  } = useBootstrapData(currentOrgId || undefined, envId, isSignedIn);

  const isLoading = !loadedLoginStatus || loadingBootstrap;
  const isError = bootstrapError || (loadedLoginStatus && !isSignedIn);

  // Fetch current org and app when bootstrap is loaded
  const currentOrg = useMemo(() => {
    if (isLoading) {
      return undefined;
    }

    return data?.org || undefined;
  }, [isLoading, data?.org]);

  const [currentApp, currentEnv] = useMemo(() => {
    if (isLoading) {
      return [];
    }

    return findActiveAppEnv(currentOrg, envId) || [];
  }, [isLoading, currentOrg, envId]);

  // always store the latest accessed org and app in storage
  useEffect(() => {
    if (currentOrg?.id) {
      setStoredOrg(currentOrg.id);
    }
  }, [currentOrg?.id]);

  useEffect(() => {
    if (currentOrg?.id && currentApp?.id) {
      setStoredApp(currentOrg.id, currentApp.id);
    }
  }, [currentOrg?.id, currentApp?.id]);

  useEffect(() => {
    if (currentOrg?.id && currentEnv?.id) {
      setStoredEnv(currentOrg.id, currentEnv.id);
    }
  }, [currentOrg?.id, currentEnv?.id]);

  if (isError) {
    void signOut().then(() => {
      navigateToLogin();
    });
  }

  const value: AuthContextType = {
    isAuthenticated: bootstrapSuccess,
    isLoading: isLoading || isError,
    user: data?.user,
    currentOrg,
    currentApp,
    currentEnv,
    setActiveOrg,
    clearActiveApp: () => clearStoredApp(currentOrg?.id),
    clearActiveEnv: () => clearStoredEnv(currentApp, currentOrg?.id),
    signOut: (redirectUrl?: string) => signOut({ redirectUrl }),
  };

  return (
    <AuthContext.Provider key={currentEnv?.id} value={value}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  return useContext<AuthContextType>(AuthContext);
}
