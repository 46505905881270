import {
  BoxProps,
  Flex,
  Progress,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import dayjs from "dayjs";

import { TRIAL_PERIOD_DAYS } from "@bucketco/shared/constants";

import { useAuthContext } from "@/auth/contexts/authContext";
import pluralize from "@/common/utils/pluralize";

export function TrialStatus(props: BoxProps) {
  const progressColor = useColorModeValue("yellow.500", "yellow.600");
  const finishedColor = useColorModeValue("red.500", "red.400");

  const { currentApp, currentOrg } = useAuthContext();

  if (
    currentApp === undefined ||
    currentOrg === undefined ||
    currentOrg.trialEndsAt === null
  ) {
    return null;
  }

  const daysRemaining = dayjs(currentOrg.trialEndsAt).diff(new Date(), "days");

  // Prevent full progress bar when number of remaining days exceeds the usual
  // trial period - e.g. if we manually extend it for somebody
  const daysUsed = Math.max(0, TRIAL_PERIOD_DAYS - daysRemaining);

  const label = getTrialStatusLabel(new Date(currentOrg.trialEndsAt));

  return (
    <Flex {...props} direction="column" gap={1.5}>
      <Text fontSize="sm">{label}</Text>
      <Progress
        color={daysRemaining < 0 ? finishedColor : progressColor}
        h={1.5}
        max={TRIAL_PERIOD_DAYS}
        value={daysUsed}
      />
    </Flex>
  );
}

export function getTrialStatusLabel(date: Date) {
  const today = new Date();
  const daysRemaining = dayjs(date).diff(today, "days");

  const label =
    daysRemaining < 0
      ? `Pro trial has expired`
      : `${daysRemaining} ${pluralize("day", daysRemaining)} left of Pro trial`;

  return label;
}
