import { RiMoonLine, RiSunLine } from "react-icons/ri";
import { useColorMode, useColorModeValue } from "@chakra-ui/color-mode";
import { Flex, IconButton, Tooltip } from "@chakra-ui/react";

import FeatureTargetingManager from "@/admin/components/FeatureTargetingManager";
import { useAuthContext } from "@/auth/contexts/authContext";

import { AppProfileMenu } from "./AppProfileMenu";

export function AppFooter() {
  const { colorMode, toggleColorMode } = useColorMode();
  const { user } = useAuthContext();

  return (
    <Flex align="center" gap={1} justify="space-between" px={2}>
      <AppProfileMenu />

      {user?.isAdmin && <FeatureTargetingManager />}

      <Tooltip
        label={`${colorMode === "dark" ? "Light" : "Dark"} mode`}
        hasArrow
      >
        <IconButton
          _hover={{ color: useColorModeValue("gray.800", "gray.200") }}
          aria-label="Toggle color mode"
          color="dimmed"
          icon={
            colorMode === "dark" ? (
              <RiSunLine size={17} />
            ) : (
              <RiMoonLine size={16} />
            )
          }
          mr={-3}
          size="md"
          variant="ghost"
          isRound
          onClick={toggleColorMode}
        />
      </Tooltip>
    </Flex>
  );
}
