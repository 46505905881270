import { Outlet, useParams } from "react-router-dom";
import { Flex } from "@chakra-ui/react";

import { useFeature } from "@/common/hooks/useFeatureFlags";
import { SetupInstructions } from "@/feature/components/SetupInstructions";
import { useFeatureData } from "@/feature/data/useFeatureData";
import { FlagTargeting } from "@/flags/components/FlagTargeting";
import FeatureSideBarWrapper from "../components/FeatureSidebarWrapper";

export default function FeatureTargeting() {
  const { featureId } = useParams();
  const { data: feature } = useFeatureData(featureId!);

  const { isEnabled: hasReorganizedFeatureTabs } = useFeature(
    "reorganized-feature-tabs",
  );

  return (
    <FeatureSideBarWrapper>
      <Flex direction="column" flexGrow={1} h="full" px={6} py={4}>
        <SetupInstructions
          featureId={featureId}
          title={
            hasReorganizedFeatureTabs ? "Install access checking" : undefined
          }
        />
        {feature?.flagId && <FlagTargeting flagId={feature.flagId} />}
        <Outlet />
      </Flex>
    </FeatureSideBarWrapper>
  );
}
