import { useMemo } from "react";
import { RiArrowDownSLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  ButtonProps,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spinner,
  useColorModeValue,
  useStyleConfig,
} from "@chakra-ui/react";

import { GlobalSettingsUrl } from "@bucketco/shared/urls";

import { useFeatureViewsData } from "@/app/data/useFeatureViewsData";
import { useAuthContext } from "@/auth/contexts/authContext";
import FeatureViewsIcon from "@/common/assets/feature-views-icon.svg?react";
import { HeaderLayoutHeading } from "@/common/components/HeaderLayout";
import InfoIconTooltip from "@/common/components/InfoIconTooltip";
import MenuDescription from "@/common/components/MenuDescription";
import { NavLink } from "@/common/components/NavLink";
import { useSelectedFeatureView } from "../hooks/useSelectedFeatureView";

type FeatureViewSelectProps = Omit<ButtonProps, "size"> & {
  size?: "sm" | "md";
};

const sizeMap = {
  sm: {
    arrowIconSize: "md",
    menuButtonSize: "sm",
    headingSize: "sm",
  },
  md: {
    arrowIconSize: "lg",
    menuButtonSize: "md",
    headingSize: "lg",
  },
};

export function FeatureViewSelect({
  size = "md",
  ...rest
}: FeatureViewSelectProps) {
  const { currentEnv } = useAuthContext();
  const [view] = useSelectedFeatureView();

  const sizes = sizeMap[size];

  const { data: featureViews = [], isLoading: isLoadingFeatureViews } =
    useFeatureViewsData();

  const featuresMenu = useMemo(
    () =>
      featureViews
        .sort((a, b) =>
          a.isAllFeatureView
            ? -1
            : b.isAllFeatureView
            ? 1
            : a.name.localeCompare(b.name),
        )
        .map((view) => ({
          id: view.id,
          to: `?view=${view.id}`,
          label: view.name,
        })),
    [featureViews],
  );

  const currentItemColor = useColorModeValue("brand.500", "brand.300");

  return (
    <Menu>
      <MenuButton
        aria-label="Switch feature view"
        as={Button}
        display="flex"
        pl={2}
        pr={1}
        rightIcon={
          <Box fontSize={sizes.arrowIconSize} ml={-0.5}>
            <RiArrowDownSLine />
          </Box>
        }
        size={sizes.menuButtonSize}
        variant="outlineOnHover"
        {...rest}
      >
        <HStack spacing={1.5}>
          <FeatureViewsIcon height="16px" width="16px" />
          <HeaderLayoutHeading fontSize={sizes.headingSize}>
            {view?.name}
          </HeaderLayoutHeading>
        </HStack>
      </MenuButton>
      <MenuList>
        <HStack fontSize="sm">
          <MenuDescription maxW="xs" pr={0}>
            Switch feature view
          </MenuDescription>
          <InfoIconTooltip text="Use feature views to group features, enable slack reporting and save column configuration." />
        </HStack>
        <MenuDivider my={0} />
        {isLoadingFeatureViews ? (
          <Box pb={1} pt={2} px={3}>
            <Spinner size="sm" />
          </Box>
        ) : (
          featuresMenu.map((i) => (
            <MenuItem
              key={i.id}
              as={NavLink}
              icon={
                <Box color="dimmed">
                  <FeatureViewsIcon height="16px" width="16px" />
                </Box>
              }
              matchSearchParams={["view"]}
              sx={{
                "&[aria-current='page']": {
                  color: currentItemColor,
                },
              }}
              to={i.to}
            >
              {i.label}
            </MenuItem>
          ))
        )}
        <MenuDivider my={0} />

        <MenuItem
          as={Link}
          display="block"
          m="2"
          sx={useStyleConfig("Button", {
            variant: "outline",
            size: "xs",
          })}
          to={GlobalSettingsUrl(currentEnv!, "app-feature-views")}
          width="max-content"
        >
          Manage views
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
