import { useFieldArray, useFormContext } from "react-hook-form";
import { RiFileCopyLine } from "react-icons/ri";
import {
  Box,
  BoxProps,
  Button,
  Card,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Tooltip,
  useClipboard,
  VStack,
} from "@chakra-ui/react";

import { EventSelectorType } from "@bucketco/shared/featureAPI";

import PointerPressIcon from "@/common/assets/pointer-press.svg?react";
import { AndOrList } from "@/common/components/AndOrList";
import { DeleteIconButton } from "@/common/components/CommonIconButtons";
import { useFeature } from "@/common/hooks/useFeatureFlags";
import { FormEventAttributeFilterPickerInput } from "@/feature/components/Form/FormEventAttributeFilterPicker";
import { FormEventAutoCompleteInputOnly } from "@/feature/components/Form/FormEventAutoCompleteInput";

function EventBlock({ children, ...props }: BoxProps) {
  return (
    <Card key="feature-key" {...props} p={4} variant="filled" w="full">
      {children}
    </Card>
  );
}

export function FormEventBasedFilterInputs({
  name,
  w,
  featureKey,
}: {
  name: string;
  w?: string;
  featureKey?: string;
}) {
  const form = useFormContext();
  const formSelectors = useFieldArray({
    control: form.control,
    name: name,
  });

  const { hasCopied, onCopy } = useClipboard(featureKey ?? "");

  const { isEnabled: hasReorganizedFeatureTabs } = useFeature(
    "reorganized-feature-tabs",
  );

  return (
    <VStack align="flex-start" w={w}>
      <AndOrList
        conjunction="or"
        conjunctionProps={{ ml: 4, my: -1, zIndex: 1 }}
        direction="vertical"
        gap="0"
        w="full"
      >
        {featureKey && (
          <EventBlock pr={4}>
            <HStack w="full">
              <InputGroup>
                <InputLeftElement color="dimmed" pointerEvents="none">
                  <PointerPressIcon height="16px" width="16px" />
                </InputLeftElement>
                <Input value={featureKey} isReadOnly />
              </InputGroup>
              <Tooltip
                closeOnClick={false}
                label={hasCopied ? "Copied!" : "Copy to clipboard"}
              >
                <IconButton
                  aria-label="Copy to clipboard"
                  borderRadius="md"
                  icon={<RiFileCopyLine />}
                  p={0}
                  size="sm"
                  variant="outline"
                  onClick={onCopy}
                />
              </Tooltip>
            </HStack>
            <Text color="dimmed" fontSize="sm" mt={2}>
              {hasReorganizedFeatureTabs
                ? "Use the feature key for tracking feature adoption"
                : "Use the feature key for usage tracking"}
            </Text>
          </EventBlock>
        )}
        {formSelectors.fields.map((field, index) => (
          <EventBlock key={field.id}>
            <FormEventSelectorInput
              key={field.id}
              nameBase={`${name}.${index}`}
            />

            <DeleteIconButton
              // isDisabled={formSelectors.fields.length < 2}
              label={formSelectors.fields.length < 2 ? "" : "Remove"}
              position="absolute"
              right={4}
              top={4}
              onClick={() => formSelectors.remove(index)}
            />
          </EventBlock>
        ))}
      </AndOrList>

      <Button
        isDisabled={form.formState.isSubmitting}
        size="sm"
        variant="input"
        onClick={() => formSelectors.append({ name: "", filter: [] })}
      >
        Add custom event
      </Button>
    </VStack>
  );
}

function FormEventSelectorInput({ nameBase }: { nameBase: string }) {
  const form = useFormContext();
  const field: EventSelectorType = form.watch(nameBase);

  return (
    <VStack align="flex-start" spacing={2}>
      <Box pr="40px" w="full">
        <FormEventAutoCompleteInputOnly
          name={`${nameBase}.name`}
          placeholder="Your event name"
        />
      </Box>

      <FormEventAttributeFilterPickerInput
        buttonText="Add attribute filter"
        eventName={field.name}
        name={`${nameBase}.filter`}
        size="sm"
      />
    </VStack>
  );
}
