import { useCallback, useEffect, useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import equal from "fast-deep-equal";

export type NavigationOptions = {
  replace?: boolean;
  state?: any;
};

export type Storage = {
  getItem: (key: string) => string | null;
  setItem: (key: string, value: string) => void;
  removeItem: (key: string) => void;
};

export type SearchParamOptions<T = string> = {
  storage?: Storage;
  storageKey?: string;
  allowlist?: readonly T[];
  serialize?: (value: T) => string;
  deserialize?: (value: string) => T | null;
};

function attemptParse(value: string | null | undefined) {
  if (typeof value !== "string") {
    return value;
  }
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
}

function getAllowedValues<T, TValue extends T | T[]>(
  value: TValue | null | undefined,
  allowlist?: readonly T[],
): TValue | null {
  if (value === null || value === undefined) {
    return null;
  }
  if (!allowlist) {
    return value;
  }
  if (Array.isArray(value)) {
    const filteredValue = value.filter((value) =>
      allowlist.some((allowed) => equal(allowed, value)),
    );
    return filteredValue.length ? (filteredValue as TValue) : null;
  } else if (allowlist.some((allowed) => equal(allowed, value))) {
    return value;
  } else {
    return null;
  }
}

export function useSearchParam<T = string>(
  key: string,
  options?: SearchParamOptions<T>,
): readonly [
  T | undefined,
  (
    newValue: T | null | undefined,
    navigationOptions?: NavigationOptions,
  ) => void,
];

export function useSearchParam<T = string>(
  key: string,
  options?: SearchParamOptions<T> & { fallback: T },
): readonly [
  T,
  (
    newValue: T | null | undefined,
    navigationOptions?: NavigationOptions,
  ) => void,
];

export function useSearchParam<T = string>(
  key: string,
  {
    fallback,
    storage,
    storageKey = `param:${key}`,
    allowlist,
    serialize,
    deserialize,
  }: SearchParamOptions<T> & { fallback?: T } = {},
) {
  const currentValue = useRef<T>();
  const [params, setParams] = useSearchParams();

  // Get the value from the URL search params and deserialize it if needed
  const paramValue = params.get(key);
  let value =
    deserialize && paramValue !== null
      ? deserialize(paramValue)
      : (paramValue as T | null);
  // If the value is not in the allowed values, discard it
  value = getAllowedValues(value, allowlist);

  // Get the stored value from the storage if it exists
  let storageValue = attemptParse(storage?.getItem(storageKey)) as
    | T
    | null
    | undefined;
  // If the stored value is not in the allowed values, discard it
  storageValue = getAllowedValues(storageValue, allowlist);
  // Update the stored value if the value from the URL search params is different
  if (value && storage && !equal(value, storageValue)) {
    storage?.setItem(storageKey, JSON.stringify(value));
  }

  useEffect(() => {
    // If the value is null or undefined and the stored value exists, update the URL search params
    if (!value && storageValue) {
      params.set(
        key,
        serialize ? serialize(storageValue) : (storageValue as string),
      );
      setParams(params, { replace: true });
    }
  }, [key, params, serialize, setParams, storageValue, value]);

  // Only update value reference if it has changed
  const nextValue = value ?? storageValue ?? fallback;
  if (!equal(currentValue.current, nextValue)) {
    currentValue.current = nextValue;
  }

  // Create a referentially stable set param function
  const setSearchParam = useCallback(
    (newValue: T | null | undefined, navigationOptions?: NavigationOptions) => {
      // Get current params rather than the one from the hook to avoid stale values
      const currentParams = new URLSearchParams(window.location.search);
      // If the new value is not in the allowed values, discard it
      newValue = getAllowedValues(newValue, allowlist);

      // If the value is null or undefined, remove the key from the URL search params and the storage
      if (newValue === null || newValue === undefined) {
        currentParams.delete(key);
        storage?.removeItem(storageKey);
      } else {
        currentParams.set(
          key,
          serialize ? serialize(newValue) : (newValue as string),
        );
        storage?.setItem(storageKey, JSON.stringify(newValue));
      }
      setParams(currentParams, { replace: true, ...navigationOptions });
    },
    [allowlist, key, serialize, setParams, storage, storageKey],
  );

  return [currentValue.current, setSearchParam] as const;
}

export type SearchArrayParamOptions<T = string> = {
  fallback?: T[];
  storage?: Storage;
  storageKey?: string;
  allowlist?: readonly T[];
  serialize?: (value: T, index: number, array: T[]) => string;
  deserialize?: (value: string, index: number, array: string[]) => T;
};

export function useSearchArrayParam<T = string>(
  key: string,
  {
    fallback,
    storage,
    storageKey = `param:${key}`,
    allowlist,
    serialize,
    deserialize,
  }: SearchArrayParamOptions<T> = {},
): readonly [
  T[],
  (newValue: T[], navigationOptions?: NavigationOptions) => void,
] {
  const currentValues = useRef<T[]>([]);
  const [params, setParams] = useSearchParams();

  // Get the values from the URL search params and deserialize them if needed
  const paramValues = params.getAll(key);
  let values = deserialize
    ? paramValues.map(deserialize)
    : (paramValues as T[]);
  // If the values are not in the allowed values, discard them
  values = getAllowedValues(values, allowlist) ?? [];

  // Get the stored values from the storage if it exists
  const storageValue = storage?.getItem(storageKey);
  let storageParsedValue = useMemo(
    () =>
      storageValue
        ? storageValue.startsWith("[")
          ? (JSON.parse(storageValue) as T[])
          : ([storageValue] as T[])
        : null,
    [storageValue],
  );
  // If the stored values are not in the allowed values, discard them
  storageParsedValue = getAllowedValues(storageParsedValue, allowlist);
  // Update the stored values if the values from the URL search params are different
  if (values.length && storage && !equal(values, storageParsedValue)) {
    storage.setItem(storageKey, JSON.stringify(values));
  }

  useEffect(() => {
    // If the value is null or undefined and the stored value exists, update the URL search params
    if (!values.length && storageParsedValue) {
      params.delete(key);
      storageParsedValue.forEach((value, index, array) =>
        params.append(
          key,
          serialize ? serialize(value, index, array) : (value as string),
        ),
      );
      setParams(params, { replace: true });
    }
  }, [key, params, serialize, setParams, storageParsedValue, values.length]);

  // Only update values reference if it has changed
  const nextValues = values.length
    ? values
    : storageParsedValue?.length
    ? storageParsedValue
    : fallback ?? [];
  if (!equal(currentValues.current, nextValues)) {
    currentValues.current = nextValues;
  }

  // Create a referentially stable set param function
  const setSearchArrayParam = useCallback(
    (newValues: T[], navigationOptions?: NavigationOptions) => {
      // Get current params rather than the one from the hook to avoid stale values
      const currentParams = new URLSearchParams(window.location.search);
      currentParams.delete(key);
      // If the new values are not in the allowed values, discard them
      newValues = getAllowedValues(newValues, allowlist) ?? [];
      if (newValues.length) {
        newValues.forEach((value, index, array) =>
          currentParams.append(
            key,
            serialize ? serialize(value, index, array) : (value as string),
          ),
        );
        storage?.setItem(storageKey, JSON.stringify(newValues));
      } else {
        storage?.removeItem(storageKey);
      }
      setParams(currentParams, { replace: true, ...navigationOptions });
    },
    [allowlist, key, serialize, setParams, storage, storageKey],
  );

  return [currentValues.current, setSearchArrayParam];
}
