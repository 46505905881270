import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { FormProvider, useForm } from "react-hook-form";
import {
  Divider,
  Flex,
  Heading,
  HStack,
  Input,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";

import { CreateOrgArgsSchema } from "@bucketco/shared/organizationAPI";
import { FeaturesUrl, GenerateDemoAppUrl } from "@bucketco/shared/urls";

import { useOrgCreateMutation } from "@/app/data/useOrgCreateMutation";
import { useOrgsData } from "@/app/data/useOrgsData";
import GoToHome from "@/auth/components/GoToHome";
import { useAuthContext } from "@/auth/contexts/authContext";
import { FormRootError } from "@/common/components/Form/FormRootError";
import FormSubmitLegacy from "@/common/components/Form/FormSubmitLegacy";
import { ManagedFormControl } from "@/common/components/Form/ManagedFormControl";
import { commonQueryKeys } from "@/common/data/commonQueryKeys";
import { useFormMutationSubmitHandler } from "@/common/hooks/useApiForm";
import { useFeature } from "@/common/hooks/useFeatureFlags";
import { useFormFieldUpdate } from "@/common/hooks/useFormFieldUpdate";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";

export default function GetStarted() {
  const { currentEnv, user } = useAuthContext();

  useEffect(() => {
    segmentAnalytics.page("Sign Up");

    // Browser tracking destinations only tracking of "Signed Up"
    // Keep the destinations opt-in here to avoid duplicate tracking on cloud
    segmentAnalytics.track(
      "Signed Up",
      {
        email: user?.email,
      },
      {
        integrations: {
          // Avoid duplicating this event on cloud destinations
          All: false,
          // Enable for all browser-only destinations
          "Twitter Ads": true,
          "LinkedIn conversion tracking": true,
        },
      },
    );
  });

  if (currentEnv) {
    return <GoToHome />;
  }

  return (
    <>
      <Helmet>
        <title>Get Started</title>
      </Helmet>
      <Flex direction="column" gap={4} w="sm">
        <Flex direction="row" gap={4}>
          <Heading fontSize="2xl">Welcome {user?.name.split(" ")[0]}</Heading>
        </Flex>
        <Text color="dimmed">
          Get started by creating your organization on Bucket
        </Text>
        <Divider />
        <Form />
      </Flex>
    </>
  );
}

function Form() {
  const queryClient = useQueryClient();
  const bootstrapQuery = queryClient.getQueryState(commonQueryKeys.bootstrap);
  const { setActiveOrg } = useAuthContext();
  const { fetchStatus } = bootstrapQuery ?? {};

  const { isEnabled: demoAppInOnboardingEnabled } = useFeature(
    "demo-app-in-onboarding",
  );

  const form = useForm({
    resolver: zodResolver(CreateOrgArgsSchema),
    mode: "onChange",
    defaultValues: {
      name: "",
      app: {
        name: "",
      },
    },
  });

  const nameSuggestion = useOrgNameSuggestion();

  useEffect(() => {
    if (nameSuggestion) {
      form.setValue("name", nameSuggestion);

      form.setValue("app.name", nameSuggestion, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    }
  }, [nameSuggestion, form]);

  const orgCreateMutation = useOrgCreateMutation();

  const handleSubmit = useFormMutationSubmitHandler(
    form,
    orgCreateMutation,
    (org) => {
      const env = org.apps?.[0]?.environments?.[0];
      if (!env) {
        console.error("[GetStarted] No app found in newly created org");
        return;
      }

      setActiveOrg(org.id, { refresh: false });

      // we're purposefully doing a full page reload here to ensure the
      // the bootstrap query is re-run and the the org is loaded correctly
      // so as to avoid getting sent back here.
      if (demoAppInOnboardingEnabled) {
        window.location.assign(GenerateDemoAppUrl(org));
      } else {
        window.location.assign(FeaturesUrl(env));
      }
    },
  );

  // generate app name from org name as you type
  useFormFieldUpdate(form, "name", (orgName) => {
    form.setValue("app.name", `${orgName}`);
  });

  // keep form disabled after successful submit while we redirect
  const isDisabled =
    form.formState.isSubmitting ||
    form.formState.isSubmitSuccessful ||
    fetchStatus === "fetching";

  return (
    <form onSubmit={handleSubmit}>
      <FormProvider {...form}>
        <VStack align="flex-start" spacing={4}>
          <ManagedFormControl
            label="Organization name"
            name="name"
            render={({ field }) => (
              <Input
                {...field}
                autoComplete="organization"
                placeholder="Acme Inc."
                size="md"
                autoFocus
              />
            )}
          />
          <DomainJoinBanner />
          <FormRootError />
          <HStack spacing={4}>
            <FormSubmitLegacy isLoading={isDisabled} size="md">
              Let&apos;s go
            </FormSubmitLegacy>
          </HStack>
        </VStack>
      </FormProvider>
    </form>
  );
}

function useOrgNameSuggestion(): string | undefined {
  const { user } = useAuthContext();

  const domainNamePart = user?.domain?.split(".")[0];

  if (!domainNamePart) {
    return undefined;
  }

  // We're ignoring possibly punycoded domains
  // Replace all non-numbers and non-letters with space
  const suggestion = domainNamePart.replace(/[^0-9A-Za-z]/g, " ");

  // Uppercase first letter
  return suggestion.charAt(0).toLocaleUpperCase() + suggestion.slice(1);
}

function DomainJoinBanner() {
  const { user } = useAuthContext();
  const { isPending, data } = useOrgsData();

  if (!user?.domain) return null;
  if (isPending) return <Spinner />;

  if (data?.orgsWithUserDomainExists) {
    return (
      <Text color="dimmed" fontSize="sm">
        An organization connected with the{" "}
        <Text as="span" color="chakra-body-text" fontWeight="semibold">
          {user.domain}
        </Text>{" "}
        domain already exists. Consider asking an admin to invite you to that
        organization instead of creating a new organization on Bucket.
      </Text>
    );
  }

  return (
    <Text color="dimmed" fontSize="sm">
      Users that sign up with an{" "}
      <Text as="span" color="chakra-body-text" fontWeight="semibold">
        @{user.domain}
      </Text>{" "}
      email will automatically join your organization
    </Text>
  );
}
