import { forwardRef, useMemo } from "react";

import { FeatureName } from "@bucketco/shared/featureAPI";

import AutocompleteSelect, {
  getStringFilterFunction,
} from "@/common/components/Autocomplete/AutocompleteSelect";
import {
  AutocompleteImplementationProps,
  AutocompleteValueIdProps,
} from "@/common/utils/autocomplete";
import { FeatureDisplay } from "@/feature/components/FeatureDisplay";
import { useFeatureNamesData } from "@/feature/data/useFeatureNamesData";

export type FeatureAutocompleteSelectProps =
  AutocompleteImplementationProps<FeatureName> &
    AutocompleteValueIdProps<FeatureName>;

export const FeatureAutocompleteSelect = forwardRef<
  HTMLButtonElement,
  FeatureAutocompleteSelectProps
>(function FeatureAutocompleteSelect(
  {
    valueAsId,
    value: valueProp,
    emphasized,
    placeholder = "Select a feature",
    canClear,
    onChange,
    ...autocompleteProps
  }: FeatureAutocompleteSelectProps,
  ref,
) {
  const { data: features = [], isLoading, isFetching } = useFeatureNamesData();

  const valueId = useMemo(
    () => (valueAsId ? valueProp : valueProp?.id),
    [valueAsId, valueProp],
  );

  const internalValue = useMemo(
    () => features.find((f) => f.id === valueId),
    [features, valueId],
  );

  const featureSuggestions = internalValue
    ? [internalValue, ...features.filter((f) => f.id !== internalValue.id)]
    : features;

  return (
    <AutocompleteSelect
      ref={ref}
      canClear={canClear}
      emphasized={emphasized}
      isLoading={isLoading}
      isLoadingSuggestions={isFetching}
      itemFilterFn={getStringFilterFunction("name")}
      itemKeyProperty="id"
      itemRenderFn={(item, search) => (
        <FeatureDisplay feature={item} highlightText={search} size="sm" />
      )}
      loadingText="Loading..."
      placeholder={placeholder}
      suggestions={featureSuggestions}
      value={internalValue}
      onChange={(newValue: FeatureName | null) => {
        if (!newValue) {
          if (canClear) onChange?.(null);
          return;
        }
        if (valueAsId) {
          onChange?.(newValue?.id ?? null);
        } else {
          onChange?.(newValue ?? null);
        }
      }}
      onClear={() => {
        if (canClear) onChange?.(null);
      }}
      {...autocompleteProps}
    />
  );
});

FeatureAutocompleteSelect.displayName = "FeatureAutocompleteSelect";
