import { ReactNode, RefObject } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import {
  AdjustAttributeFieldsCallback,
  getNewOperatorOnTypeChange,
  SingleUIFilter,
  SingleUIFilterSchema,
  UIFilterType,
} from "@bucketco/shared/filter";
import { SegmentDTOWithTotalCompanies } from "@bucketco/shared/segmentAPI";

import { FeatureTargetingFilterFields } from "@/common/components/filters/FeatureTargetingFilterItem";
import { FilterContextType } from "@/common/components/filters/FilterGroup";
import { ManagedFormControl } from "@/common/components/Form/ManagedFormControl";
import RuleType from "@/common/components/Rule/RuleType";
import { useFormFieldDiff } from "@/common/hooks/useFormFieldDiff";
import useOutsideClickPopover from "@/common/hooks/useOutsideClickPopover";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";

import { AttributeFilterFields } from "./AttributeFilterItem";
import { CompanyFeatureMetricFilterFields } from "./CompanyFeatureMetricFilterItem";
import { SegmentFilterFields } from "./SegmentFilterItem";

export type FormType = z.infer<typeof SingleUIFilterSchema>;

type FilterDropdownProps = {
  filter?: SingleUIFilter;
  types: UIFilterType[];
  adjustAttributeFields?: AdjustAttributeFieldsCallback;
  segmentItemFilter?: (segment: SegmentDTOWithTotalCompanies) => boolean;
  segmentItemFilterReason?: ReactNode;
  onSave: (nextFilter: SingleUIFilter) => void;
  warnings?: Partial<Record<UIFilterType, ReactNode>>;
  children: (options: {
    triggerRef: RefObject<HTMLElement>;
    toggle: () => void;
  }) => ReactNode;
  context?: FilterContextType;
};

export default function FilterDropdown({
  filter,
  types,
  adjustAttributeFields,
  segmentItemFilter,
  segmentItemFilterReason,
  warnings,
  onSave,
  children,
  context,
}: FilterDropdownProps) {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const { contentRef, triggerRef } = useOutsideClickPopover({
    isOpen,
    onToggle,
  });

  function handleSubmit(submitFilter: SingleUIFilter) {
    onSave(submitFilter);

    segmentAnalytics.track("Filter Changed", {
      filter_type: submitFilter.type,
      operation: filter ? "update" : "create",
      context,
    });
  }

  return (
    <Popover
      autoFocus={false}
      closeOnBlur={false}
      closeOnEsc={true}
      isOpen={isOpen}
      lazyBehavior="unmount"
      placement="bottom-start"
      trigger="click"
      isLazy
      onClose={onClose}
    >
      <PopoverTrigger>
        {children({ triggerRef, toggle: onToggle })}
      </PopoverTrigger>
      <PopoverContent ref={contentRef} maxW="64">
        <PopoverArrow />
        <PopoverBody p={0}>
          <VStack alignItems="start" p={4} pt={3}>
            <FilterForm
              adjustAttributeFields={adjustAttributeFields}
              context={context}
              filter={filter}
              segmentItemFilter={segmentItemFilter}
              segmentItemFilterReason={segmentItemFilterReason}
              types={types}
              warnings={warnings}
              onSubmit={handleSubmit}
            />
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

const DEFAULT_CREATE_STATE: Record<FilterContextType, FormType> = {
  flag: {
    type: "segment" as const,
    operator: "SEGMENT" as const,
    segmentId: "",
  },
  segment: {
    type: "companyAttribute" as const,
    field: "",
    operator: "IS" as const,
    values: [""],
  },
};

type FilterFormProps = {
  filter?: SingleUIFilter;
  types: UIFilterType[];
  segmentItemFilter?: (segment: SegmentDTOWithTotalCompanies) => boolean;
  segmentItemFilterReason?: ReactNode;
  warnings?: Partial<Record<UIFilterType, ReactNode>>;
  adjustAttributeFields?: AdjustAttributeFieldsCallback;
  onSubmit: (filter: SingleUIFilter) => void;
  context?: FilterContextType;
};

function FilterForm({
  filter,
  types,
  warnings,
  adjustAttributeFields,
  segmentItemFilter,
  segmentItemFilterReason,
  onSubmit,
  context = "segment",
}: FilterFormProps) {
  const form = useForm<FormType>({
    resolver: zodResolver(SingleUIFilterSchema),
    defaultValues: filter ?? DEFAULT_CREATE_STATE[context],
  });

  const filterType = useWatch({ name: "type", control: form.control });

  const handleSubmit = form.handleSubmit(onSubmit);

  useFormFieldDiff(form, "type", ({ oldValue, newValue }) => {
    const shouldClear = oldValue !== newValue;
    if (shouldClear) {
      form.setValue(
        "operator",
        getNewOperatorOnTypeChange(newValue, form.getValues("operator")),
      );
      form.setValue("featureId", "");
      form.setValue("metric", "funnelStep");
      form.setValue("field", "");
      form.setValue("values", [""]);
      form.setValue("segmentId", "", { shouldDirty: true });
    }
  });

  const { isDirty, isValid } = form.formState;

  return (
    <Box style={{ width: "100%" }}>
      <FormProvider {...form}>
        <VStack alignItems="start">
          <ManagedFormControl
            name="type"
            render={({ field }) => <RuleType allowed={types} {...field} />}
          />

          {filterType === "companyAttribute" ||
          filterType === "userAttribute" ||
          filterType === "otherContext" ? (
            <AttributeFilterFields
              adjustAttributeFields={adjustAttributeFields}
              type={filterType}
            />
          ) : filterType === "featureMetric" ? (
            <CompanyFeatureMetricFilterFields />
          ) : filterType === "segment" ? (
            <SegmentFilterFields
              segmentItemFilter={segmentItemFilter}
              segmentItemFilterReason={segmentItemFilterReason}
            />
          ) : filterType === "featureTargeting" ? (
            <FeatureTargetingFilterFields />
          ) : (
            <Alert status="warning">
              <AlertIcon />
              <AlertDescription>Unsupported filter type</AlertDescription>
            </Alert>
          )}

          {warnings && warnings[filterType] && (
            <Alert status="warning">
              <AlertIcon alignSelf={"start"} />
              <AlertDescription>{warnings[filterType]}</AlertDescription>
            </Alert>
          )}

          <Box pt={2}>
            <Button
              isDisabled={!isValid || !isDirty}
              variant="primary"
              onClick={handleSubmit}
            >
              {filter ? "Update" : "Add"}
            </Button>
          </Box>
        </VStack>
      </FormProvider>
    </Box>
  );
}
