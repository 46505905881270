import { Analytics } from "@segment/analytics-next";

import { mixpanelGroupPlugin } from "@bucketco/shared/analytics/mixpanelGroupPlugin";
import { SCREENSHOT_USER_AGENT } from "@bucketco/shared/screenshot";

import { DEFAULT_ORG_STORAGE_KEY } from "@/auth/contexts/authContext";
import { blockNonMemberTracking } from "@/common/utils/analytics/blockNonMembertracking";
import {
  API_URL,
  BUCKET_PUBLISHABLE_KEY,
  ENVIRONMENT,
  LINKEDIN_PARTNER_ID,
  SEGMENT_WRITE_KEY,
} from "@/common/utils/env";
import { LinkedInConversionTracking } from "@/common/utils/segment/segmentLinkedInPlugin";

declare global {
  interface Window {
    analytics: Analytics;
  }
}

const sdkVersion = "2";

/*eslint-disable*/
var analytics = ((window as any).analytics = (window as any).analytics || []);
if (!analytics.initialize) {
  if (analytics.invoked) {
    window.console &&
      console.error &&
      console.error("Segment snippet included twice.");
  } else {
    analytics.invoked = !0;
    analytics.methods = [
      "trackSubmit",
      "trackClick",
      "trackLink",
      "trackForm",
      "pageview",
      "identify",
      "reset",
      "group",
      "track",
      "ready",
      "alias",
      "debug",
      "page",
      "once",
      "off",
      "on",
      "addSourceMiddleware",
      "addIntegrationMiddleware",
      "setAnonymousId",
      "addDestinationMiddleware",
    ];
    analytics.factory = function (e: any) {
      return function () {
        var t = Array.prototype.slice.call(arguments);
        t.unshift(e);
        analytics.push(t);
        return analytics;
      };
    };
    for (var e = 0; e < analytics.methods.length; e++) {
      var key = analytics.methods[e];
      analytics[key] = analytics.factory(key);
    }
    analytics.load = function (key: string, e: any) {
      var t = document.createElement("script");
      t.type = "text/javascript";
      t.async = !0;
      t.src =
        "https://analytics-cdn.bucket.co/analytics.js/v1/" +
        key +
        "/analytics.min.js";
      var n = document.getElementsByTagName("script")[0];
      n.parentNode!.insertBefore(t, n);
      analytics._loadOptions = e;
    };
    analytics.SNIPPET_VERSION = "4.15.3";
  }
}
/*eslint-enable*/

// Use a proxy in order to avoid consumers storing a reference to the
// segment snippet array placeholder in window.analytics before it's
// replaced with the full analytics object.
export const segmentAnalytics = new Proxy(
  {},
  {
    get(_target, key: keyof Analytics) {
      return window.analytics[key];
    },
  },
) as Analytics;

segmentAnalytics.addSourceMiddleware(blockNonMemberTracking);

segmentAnalytics.ready(() => {
  segmentAnalytics.register(
    mixpanelGroupPlugin(() => localStorage.getItem(DEFAULT_ORG_STORAGE_KEY)),
  );

  if (ENVIRONMENT === "production") {
    segmentAnalytics.register(LinkedInConversionTracking(LINKEDIN_PARTNER_ID));
  }
});

export function loadAnalytics() {
  // don't track for UrlBox graph capture
  if (navigator.userAgent === SCREENSHOT_USER_AGENT) {
    return;
  }

  analytics.load(SEGMENT_WRITE_KEY, {
    integrations: {
      "Segment.io": { apiHost: `analytics-api.bucket.co/v1` },
      "Bucket Web (Actions)": {
        sdkVersion,
        host: `${API_URL}/ingest-proxy`,
        ...(BUCKET_PUBLISHABLE_KEY
          ? { trackingKey: BUCKET_PUBLISHABLE_KEY }
          : {}),
      },
    },
  });
  analytics.page();
}
