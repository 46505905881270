import { Dependee, UIFilter } from "@bucketco/shared/filter";
import { TargetAudienceEstimateRequest } from "@bucketco/shared/filterAPI";
import { UsersQuery } from "@bucketco/shared/userAPI";

const qk = {
  bootstrap: ["bootstrap"] as const,
  orgs: ["orgs"] as const,

  orgInvite: (orgId?: string, inviteKey?: string) =>
    [...qk.orgs, orgId, "invites", inviteKey] as const,

  root: (appId?: string, envId?: string) => [
    "apps",
    appId,
    "environments",
    envId,
  ],

  distinctEventNames: (appId?: string, envId?: string) =>
    [...qk.root(appId, envId), "distinctEventNames"] as const,

  users: (appId?: string, envId?: string, params?: Omit<UsersQuery, "envId">) =>
    [...qk.root(appId, envId), "users", ...(params ? [params] : [])] as const,

  user: (appId?: string, envId?: string, userId?: string) =>
    [...qk.root(appId, envId), "users", userId] as const,

  userNames: (appId?: string, envId?: string, ids?: string[]) =>
    [...qk.root(appId, envId), "user-names", ...(ids ?? [])] as const,

  companyAttributeValues: (
    appId?: string,
    envId?: string,
    params?: Record<string, string | undefined>,
  ) =>
    [
      ...qk.root(appId, envId),
      "companyAttributeValues",
      ...(params ? [params] : []),
    ] as const,

  eventAttributeValues: (
    appId?: string,
    envId?: string,
    entityValue?: string,
    attributeKey?: string,
    search?: string,
  ) =>
    [
      ...qk.root(appId, envId),
      "eventAttributeValues",
      entityValue,
      attributeKey,
      search,
    ] as const,

  eventAttributeNames: (appId?: string, envId?: string, eventName?: string) =>
    [...qk.root(appId, envId), "eventAttributeNames", eventName] as const,

  filterCheck: (appId?: string, dependee?: Dependee, filter?: UIFilter) =>
    [
      "apps",
      appId,
      "filter-check",
      dependee,
      ...(filter ? [filter] : []),
    ] as const,

  estimateTargetAudience: (
    appId?: string,
    args?: TargetAudienceEstimateRequest,
  ) => ["apps", appId, "audience-estimate", args] as const,
};

export { qk as commonQueryKeys };
