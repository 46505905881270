import {
  Center,
  Flex,
  FormLabel,
  HStack,
  Link,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { SalesEmail } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import {
  IncompleteBarCells,
  IncompleteBarPattern,
} from "@/common/charts/components/IncompleteBar";
import useChartTokens from "@/common/charts/hooks/useChartTokens";
import AnimatedSpinner from "@/common/components/AnimatedSpinner";
import CompactNumber from "@/common/components/CompactNumber";
import SimpleTable from "@/common/components/SimpleTable";
import { locale } from "@/common/utils/locale";
import { formatLargeNumber } from "@/common/utils/numberFormatting";
import { TrialStatus } from "@/global-settings/components/TrialStatus";
import { useBillingUsage } from "@/global-settings/data/useBillingUsage";

const monthDateFormatter = new Intl.DateTimeFormat(locale, {
  year: "numeric",
  month: "long",
});

const shortMonthFormatter = new Intl.DateTimeFormat(locale, {
  year: "2-digit",
  month: "short",
});

function isCurrentMonth(date: Date): boolean {
  return dayjs().isSame(date, "month");
}

function zeroFillYear(
  actualUsage: Array<{ month: Date; trackedUsers: number }>,
) {
  const result = new Array(12).fill(0).map((_, idx, arr) => {
    return {
      month: dayjs()
        .startOf("month")
        .subtract(arr.length - idx, "month")
        .toDate(),
      trackedUsers: 0,
    };
  });

  result.splice(-actualUsage.length, actualUsage.length, ...actualUsage);

  return result;
}

export default function OrganizationBilling() {
  const { currentOrg } = useAuthContext();
  const { colors, fontSizes, radii } = useChartTokens();
  const billingUsage = useBillingUsage();

  const trackingData = (billingUsage.data || []).slice(0, 12).reverse();
  const graphData = zeroFillYear(trackingData);

  return (
    <Flex direction={"column"} gap="5" maxWidth="wideForm">
      <Flex direction={"column"} gap="3">
        <FormLabel pb={0}>Payment plan</FormLabel>
        {currentOrg?.trialEndsAt !== null ? (
          <>
            <Text color="dimmed">
              You are currently on a &apos;Pro&apos; plan trial. You will
              automatically be downgraded to the free &apos;starter&apos; plan
              when the trial ends.
            </Text>
            <Text color="dimmed">
              <Link
                color="chakra-body-text"
                href="https://bucket.co/pricing"
                target="_blank"
              >
                See pricing plans
              </Link>{" "}
              and{" "}
              <Link color="chakra-body-text" href={`mailto:${SalesEmail()}`}>
                contact sales
              </Link>{" "}
              to upgrade.
            </Text>

            <TrialStatus w={"md"} />
          </>
        ) : (
          <Text color="dimmed">
            You are currently on the{" "}
            <Text as="strong" color="chakra-body-text">
              {currentOrg?.accessLevel}
            </Text>
            -plan.{" "}
            <Link color="chakra-body-text" href={`mailto:${SalesEmail()}`}>
              Contact sales
            </Link>{" "}
            to {currentOrg?.accessLevel === "enterprise" ? "adjust" : "upgrade"}{" "}
            your plan.
          </Text>
        )}
      </Flex>

      <Flex direction={"column"} gap="3">
        <FormLabel pb={0}>Monthly tracked users</FormLabel>

        <Text maxW="lg" size="sm" variant="dimmed">
          Count of Monthly Tracked Users (MTU) per calendar month. Users are
          tracked across all apps in the organization.
        </Text>

        <ResponsiveContainer height={300} width="100%">
          {billingUsage.isLoading ? (
            <Center>
              <AnimatedSpinner color="dimmed" size="md" show />
            </Center>
          ) : (
            <BarChart
              data={graphData}
              margin={{
                top: 10, //why is this necessary to match axis labels
                right: 10, //why is this necessary to match axis labels
                bottom: 0,
                left: 0,
              }}
            >
              <CartesianGrid
                stroke={colors.grid}
                strokeDasharray="4"
                vertical={false}
              />
              <ReferenceLine
                stroke={colors.grid}
                y={0}
                yAxisId="left"
                isFront
              />
              <XAxis
                axisLine={false}
                dataKey="month"
                dy={8}
                fontSize={fontSizes.axisLabel}
                height={50}
                stroke={colors.axis}
                tickFormatter={(val) =>
                  val instanceof Date ? shortMonthFormatter.format(val) : ""
                }
                tickLine={true}
              ></XAxis>
              <YAxis
                allowDecimals={false}
                axisLine={false}
                fontSize={fontSizes.axisLabel}
                stroke={colors.axis}
                tick={{
                  dx: 4,
                }}
                tickFormatter={formatLargeNumber}
                tickLine={false}
                type="number"
                width={50}
                yAxisId="left"
              ></YAxis>
              <Tooltip
                contentStyle={{
                  borderColor: colors.tooltipBorder,
                  backgroundColor: colors.tooltipBg,
                  borderRadius: radii.tooltip,
                  fontSize: fontSizes.tooltip,
                }}
                isAnimationActive={false}
                labelFormatter={(val) =>
                  val instanceof Date ? monthDateFormatter.format(val) : ""
                }
              />
              {!billingUsage.isLoading && (
                <>
                  <defs>
                    <IncompleteBarPattern
                      colorName={colors.primary}
                      colorValue={colors.primary}
                    />
                  </defs>
                  <Bar
                    key="trackedUsers"
                    animationDuration={300}
                    dataKey="trackedUsers"
                    fill={colors.primary}
                    name="Tracked users"
                    radius={[7, 7, 0, 0]}
                    stackId="a"
                    yAxisId="left"
                  >
                    {IncompleteBarCells({
                      data: graphData,
                      color: colors.primary,
                    })}
                  </Bar>
                </>
              )}
            </BarChart>
          )}
        </ResponsiveContainer>

        <SimpleTable
          columns={["Month", "Users"]}
          rows={billingUsage.data || []}
          rowTemplate={(d) => (
            <Tr key={monthDateFormatter.format(new Date(d.month))}>
              <Td>
                <HStack>
                  <Text as="span">
                    {monthDateFormatter.format(new Date(d.month))}
                  </Text>
                  {isCurrentMonth(d.month) && (
                    <Text as="span" variant={"dimmed"}>
                      (current)
                    </Text>
                  )}
                </HStack>
              </Td>
              <Td>
                <CompactNumber value={d.trackedUsers} />
              </Td>
            </Tr>
          )}
        ></SimpleTable>
      </Flex>
    </Flex>
  );
}
