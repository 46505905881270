import { forwardRef, useMemo, useState } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";

import { UserDTO, UsersQuery } from "@bucketco/shared/userAPI";

import AutocompleteSelect, {
  getStringFilterFunction,
  highlightStringMatch,
} from "@/common/components/Autocomplete/AutocompleteSelect";
import { commonQueryKeys } from "@/common/data/commonQueryKeys";
import { useUser, useUsersSearch } from "@/common/data/useUsers";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import {
  AutocompleteImplementationProps,
  AutocompleteValueIdProps,
} from "@/common/utils/autocomplete";

const filterFunctions = [
  getStringFilterFunction("name"),
  getStringFilterFunction("email"),
  getStringFilterFunction("id"),
];
export const userAutocompleteFilterFn = (
  user: UserDTO,
  search: string,
): boolean => {
  return filterFunctions.some((f) => f(user, search));
};

export type UserAutocompleteSelectProps =
  AutocompleteImplementationProps<UserDTO> &
    AutocompleteValueIdProps<UserDTO> & {
      query?: Omit<UsersQuery, "envId">;
    };

export const UserAutocompleteSelect = forwardRef<
  HTMLButtonElement,
  UserAutocompleteSelectProps
>(function UserAutocompleteSelect(
  {
    valueAsId,
    value: valueProp,
    query,
    placeholder = "Select a user",
    canClear,
    onChange,
    ...autocompleteProps
  }: UserAutocompleteSelectProps,
  ref,
) {
  const { appId, envId } = useCurrentEnv();
  const valueId = useMemo(
    () => (valueAsId ? valueProp : valueProp?.id),
    [valueAsId, valueProp],
  );
  const queryClient = useQueryClient();
  const [searchValue, setSearchValue] = useState("");

  const { data: valueEnhancement, isLoading: isLoadingEnhancement } = useUser(
    valueId ?? undefined,
  );

  const {
    data: searchUsers = [],
    isLoading,
    isFetching,
  } = useUsersSearch(searchValue, query);

  const internalValue = useMemo(() => {
    return valueProp && valueProp === valueEnhancement?.id
      ? valueEnhancement
      : undefined;
  }, [valueProp, valueEnhancement]);

  const suggestions = internalValue
    ? [internalValue, ...searchUsers.filter((u) => u.id !== internalValue.id)]
    : searchUsers;

  return (
    <AutocompleteSelect
      ref={ref}
      canClear={canClear}
      isLoading={isLoading || isLoadingEnhancement}
      isLoadingSuggestions={isFetching}
      itemFilterFn={userAutocompleteFilterFn}
      itemKeyProperty="id"
      itemRenderFn={(u, search) => (
        <Flex alignItems="center" gap={2} py={0.5} whiteSpace="nowrap">
          <Text>
            {search
              ? highlightStringMatch(u.name || u.id, search)
              : u.name || u.id}
          </Text>
          {u.email ? (
            <Text>
              &lt;
              {search ? highlightStringMatch(u.email, search) : u.email}
              &gt;
            </Text>
          ) : null}
          {u.name ? (
            <Text color="dimmedDisabled">
              ({search ? highlightStringMatch(u.id, search) : u.id})
            </Text>
          ) : null}
        </Flex>
      )}
      loadingText="Loading..."
      placeholder={placeholder}
      suggestions={suggestions}
      value={internalValue}
      onChange={(newValue: UserDTO | null) => {
        setSearchValue("");
        if (!newValue) {
          if (canClear) onChange?.(null);
          return;
        }
        // If a value is selected, pre-seed the single user query for immediate display
        queryClient.setQueryData(
          commonQueryKeys.user(appId, envId, newValue.id),
          [newValue],
        );
        // If valueAsId is true, return only the id of the selected user
        if (valueAsId) {
          onChange?.(newValue?.id ?? null);
        } else {
          onChange?.(newValue ?? null);
        }
      }}
      onClear={() => {
        setSearchValue("");
      }}
      onSearchInput={setSearchValue}
      {...autocompleteProps}
    />
  );
});
