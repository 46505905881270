import { useParams } from "react-router-dom";

import { useFeatureViewParam } from "@/common/hooks/useFeatureViewParam";
import { useTableDisplayModeParam } from "@/common/hooks/useTableDisplayMode";
import { useCompanyFeaturesData } from "@/company/data/useCompanyFeaturesData";
import { useCompanyFeaturesTableState } from "@/company/hooks/useCompanyTableStates";
import { CompanyFeaturesTable } from "../components/CompanyFeaturesTable";

export default function CompanyFeatures() {
  const { companyId } = useParams();
  const [displayMode] = useTableDisplayModeParam();
  const [viewId] = useFeatureViewParam();

  const tableState = useCompanyFeaturesTableState();

  const { sorting } = tableState;
  // We only handle sorting one column for now
  const { id: sortBy, desc: sortDesc } = sorting[0] ?? {};
  const { data, isLoading, isFetching } = useCompanyFeaturesData(companyId, {
    viewId,
    sortBy,
    sortOrder:
      sortDesc === true ? "desc" : sortDesc === false ? "asc" : undefined,
    sortType: displayMode === "tree" ? "hierarchical" : "flat",
  });

  return (
    <CompanyFeaturesTable
      data={data}
      isFetching={isFetching}
      isLoading={isLoading}
    />
  );
}
